const manifest = {
  plugin_id: 3678,
  identifier: 'responsive_nav_bar_drawer',
  manifest_version: '0.0.1-alpha-16',
  dependency_name: '',
  name: 'Nav Bar Drawer',
  description: 'Nav Bar Drawer',
  type: 'nav_item',
  supported_nav_items: [
    'responsive_nav_bar_menu_list',
    'responsive_nav_bar_button',
  ],
  screen: false,
  ui_builder_support: true,
  characteristics: { group: true },
  data: {
    fields: [],
  },
  localizations: {
    fields: [
      {
        key: 'toggle_closed_label_text',
        label: 'Closed label text',
        initial_value: 'Menu',
        type: 'text_input',
      },
      {
        key: 'toggle_open_label_text',
        label: 'Open label text',
        initial_value: 'Close',
        type: 'text_input',
      },
    ],
  },
  assets: {
    // #region Button Asset
    fields: [
      {
        group: true,
        label: 'Button Asset',
        folded: false,
        fields: [
          {
            key: 'asset_switch',
            type: 'switch',
            label: 'Enable',
            initial_value: false,
          },
          {
            key: 'asset_width',
            label: 'Width',
            type: 'number_input',
            initial_value: 24,
            conditional_fields: [
              {
                key: 'assets/asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'asset_height',
            label: 'Height',
            type: 'number_input',
            initial_value: 24,
            conditional_fields: [
              {
                key: 'assets/asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'asset_alignment',
            label: 'Alignment',
            type: 'select',
            options: [
              {
                text: 'Left',
                value: 'left',
              },
              {
                text: 'Right',
                value: 'right',
              },
            ],
            initial_value: 'left',
            conditional_fields: [
              {
                key: 'assets/asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'asset_margin_top',
            label: 'Margin top',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'asset_margin_right',
            label: 'Margin right',
            type: 'number_input',
            initial_value: 8,
            conditional_fields: [
              {
                key: 'assets/asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'asset_margin_bottom',
            label: 'Margin bottom',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'asset_margin_left',
            label: 'Margin left',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'assets/asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'toggle_closed_default_asset',
            label: 'Closed default asset',
            type: 'uploader',
            conditional_fields: [
              {
                key: 'assets/asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'toggle_enable_closed_hover_switch',
            label: 'Enable closed hover asset',
            type: 'switch',
            initial_value: false,
            conditional_fields: [
              {
                key: 'assets/asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'toggle_closed_hover_asset',
            label: 'Closed hover asset',
            type: 'uploader',
            conditional_fields: [
              {
                key: 'assets/asset_switch',
                condition_value: true,
              },
              {
                key: 'assets/toggle_enable_closed_hover_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'toggle_open_default_asset',
            label: 'Open default asset',
            type: 'uploader',
            conditional_fields: [
              {
                key: 'assets/asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'toggle_enable_open_hover_switch',
            label: 'Enable open hover asset',
            type: 'switch',
            initial_value: false,
            conditional_fields: [
              {
                key: 'assets/asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'toggle_open_hover_asset',
            label: 'Open hover asset',
            type: 'uploader',
            conditional_fields: [
              {
                key: 'assets/asset_switch',
                condition_value: true,
              },
              {
                key: 'assets/toggle_enable_open_hover_switch',
                condition_value: true,
              },
            ],
          },
        ],
      },
    ],
    // #endregion
  },
  styles: {
    // #region Button Title
    fields: [
      {
        group: true,
        label: 'Button Title',
        folded: false,
        fields: [
          {
            key: 'title_switch',
            label: 'Enable',
            type: 'switch',
            initial_value: true,
          },
          {
            key: 'toggle_title_closed_default_font_color',
            label: 'Closed default font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(158, 158, 158, 1)',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'toggle_title_closed_hover_font_color',
            label: 'Closed hover font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'toggle_title_open_default_font_color',
            label: 'Open default font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(158, 158, 158, 1)',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'toggle_title_open_hover_font_color',
            label: 'Open hover font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'title_font_family',
            label: 'Font family',
            type: 'web_font_selector',
            initial_value: 'Ubuntu-Medium',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'title_font_size',
            label: 'Font size',
            type: 'number_input',
            initial_value: 14,
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'title_line_height',
            label: 'Line height',
            type: 'number_input',
            initial_value: 24,
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'title_letter_spacing',
            label: 'Letter spacing',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'title_text_transform',
            label: 'Text transform',
            type: 'select',
            options: [
              { text: 'None', value: 'none' },
              { text: 'Capitalize', value: 'capitalize' },
              { text: 'Uppercase', value: 'uppercase' },
              { text: 'Lowercase', value: 'lowercase' },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'toggle_title_closed_default_text_decoration',
            label: 'Closed default text decoration',
            type: 'select',
            options: [
              { text: 'None', value: 'none' },
              { text: 'Underline', value: 'underline' },
              { text: 'Strikethrough', value: 'line-through' },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'toggle_title_closed_hover_text_decoration',
            label: 'Closed hover text decoration',
            type: 'select',
            options: [
              { text: 'None', value: 'none' },
              { text: 'Underline', value: 'underline' },
              { text: 'Strikethrough', value: 'line-through' },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'toggle_title_open_default_text_decoration',
            label: 'Open default text decoration',
            type: 'select',
            options: [
              { text: 'None', value: 'none' },
              { text: 'Underline', value: 'underline' },
              { text: 'Strikethrough', value: 'line-through' },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'toggle_title_open_hover_text_decoration',
            label: 'Open hover text decoration',
            type: 'select',
            options: [
              { text: 'None', value: 'none' },
              { text: 'Underline', value: 'underline' },
              { text: 'Strikethrough', value: 'line-through' },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
        ],
      },
      // #endregion
      // #region Button Styling
      {
        group: true,
        label: 'Button Styling',
        folded: false,
        fields: [
          {
            key: 'size',
            label: 'Button Size',
            type: 'select',
            options: [
              {
                text: 'Dynamic',
                value: 'dynamic',
              },
              {
                text: 'Fixed',
                value: 'fixed',
              },
            ],
            initial_value: 'dynamic',
          },
          {
            key: 'width',
            label: 'Button width',
            type: 'number_input',
            initial_value: 120,
            conditional_fields: [
              {
                key: 'styles/size',
                condition_value: 'fixed',
              },
            ],
          },
          {
            key: 'button_content_alignment',
            label: 'Button Content Alignment',
            type: 'select',
            options: [
              {
                text: 'Left',
                value: 'justify-start',
              },
              {
                text: 'Center',
                value: 'justify-center',
              },
              {
                text: 'Right',
                value: 'justify-end',
              },
            ],
            initial_value: 'justify-center',
          },
          {
            key: 'underline_thickness',
            label: 'Underline thickness',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'underline_corner_radius',
            label: 'Underline corner radius',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'closed_default_underline_color',
            label: 'Closed default underline color',
            type: 'color_picker_rgba',
            initial_value: 'transparent',
          },
          {
            key: 'closed_hover_underline_color',
            label: 'Closed hover underline color',
            type: 'color_picker_rgba',
            initial_value: 'transparent',
          },
          {
            key: 'open_default_underline_color',
            label: 'Open default underline color',
            type: 'color_picker_rgba',
            initial_value: 'transparent',
          },
          {
            key: 'open_hover_underline_color',
            label: 'Open hover underline color',
            type: 'color_picker_rgba',
            initial_value: 'transparent',
          },
          {
            key: 'item_margin_top',
            label: 'Item margin top',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'item_margin_right',
            label: 'Item margin right',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'item_margin_bottom',
            label: 'Item margin bottom',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'item_margin_left',
            label: 'Item margin left',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'item_padding_top',
            label: 'Item padding top',
            type: 'number_input',
            initial_value: 8,
          },
          {
            key: 'item_padding_right',
            label: 'Item padding right',
            type: 'number_input',
            initial_value: 16,
          },
          {
            key: 'item_padding_bottom',
            label: 'Item padding bottom',
            type: 'number_input',
            initial_value: 8,
          },
          {
            key: 'item_padding_left',
            label: 'Item padding left',
            type: 'number_input',
            initial_value: 16,
          },
          {
            key: 'toggle_closed_default_item_background_color',
            label: 'Closed default item background color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
          },
          {
            key: 'toggle_closed_hover_item_background_color',
            label: 'Closed hover item background color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(30, 30, 30, 1)',
          },
          {
            key: 'toggle_open_default_item_background_color',
            label: 'Open default item background color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
          },
          {
            key: 'toggle_open_hover_item_background_color',
            label: 'Open hover item background color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(30, 30, 30, 1)',
          },
          {
            key: 'toggle_closed_default_item_border_color',
            label: 'Closed default item border color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
          },
          {
            key: 'toggle_closed_hover_item_border_color',
            label: 'Closed hover item border color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
          },
          {
            key: 'toggle_open_default_item_border_color',
            label: 'Open default item border color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
          },
          {
            key: 'toggle_open_hover_item_border_color',
            label: 'Open hover item border color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
          },
          {
            key: 'item_border_thickness',
            label: 'Item border thickness',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'item_corner_radius',
            label: 'Item corner radius',
            type: 'number_input',
            initial_value: 8,
          },
        ],
      },
      // #endregion
      // #region Drawer
      {
        group: true,
        label: 'Drawer',
        folded: false,
        fields: [
          {
            key: 'mode',
            label: 'Drawer Mode',
            type: 'select',
            options: [
              {
                text: 'Fullscreen',
                value: 'fullscreen',
              },
              {
                text: 'Modal',
                value: 'modal',
              },
            ],
            initial_value: 'fullscreen',
          },
          {
            key: 'drawer_max_width_switch',
            label: 'Enable max width',
            type: 'switch',
            initial_value: false,
            conditional_fields: [
              {
                key: 'styles/mode',
                condition_value: 'modal',
              },
            ],
          },
          {
            key: 'drawer_max_width',
            label: 'Max width',
            type: 'number_input',
            conditional_fields: [
              {
                key: 'styles/drawer_max_width_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'drawer_alignment',
            label: 'Drawer alignment',
            type: 'select',
            options: [
              {
                text: 'Left',
                value: 'left',
              },
              {
                text: 'Center',
                value: 'center',
              },
              {
                text: 'Right',
                value: 'right',
              },
            ],
            initial_value: 'left',
            conditional_fields: [
              {
                key: 'styles/drawer_max_width_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'align_vertical',
            label: 'Drawer contents vertical alignment',
            type: 'select',
            options: [
              {
                text: 'Top',
                value: 'flex-start',
              },
              {
                text: 'Center',
                value: 'center',
              },
              {
                text: 'Bottom',
                value: 'flex-end',
              },
            ],
            initial_value: 'flex-start',
          },
          {
            key: 'align_horizontal',
            label: 'Drawer contents horizontal alignment',
            type: 'select',
            options: [
              {
                text: 'Left',
                value: 'flex-start',
              },
              {
                text: 'Center',
                value: 'center',
              },
              {
                text: 'Right',
                value: 'flex-end',
              },
            ],
            initial_value: 'flex-start',
          },
          {
            key: 'overlay_color',
            label: 'Scrim/overlay color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0.5)',
          },
          {
            key: 'vertical_gutter',
            label: 'Vertical gutter',
            type: 'number_input',
            initial_value: 12,
          },
          {
            key: 'padding_top',
            label: 'Padding top',
            type: 'number_input',
            initial_value: 104,
          },
          {
            key: 'padding_right',
            label: 'Padding right',
            type: 'number_input',
            initial_value: 24,
          },
          {
            key: 'padding_bottom',
            label: 'Padding bottom',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'padding_left',
            label: 'Padding left',
            type: 'number_input',
            initial_value: 24,
          },
          {
            key: 'margin_top',
            label: 'Margin top',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'margin_right',
            label: 'Margin right',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'margin_bottom',
            label: 'Margin bottom',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'margin_left',
            label: 'Margin left',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'background_color',
            label: 'Background color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(17, 17, 17, 1)',
          },
          {
            key: 'border_color',
            label: 'Border color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
          },
          {
            key: 'border_thickness',
            label: 'Border thickness',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'corner_radius',
            label: 'Corner radius',
            type: 'number_input',
            initial_value: 8,
          },
        ],
      },
      // #endregion
      // #region Effects
      {
        group: true,
        label: 'Effects',
        folded: true,
        fields: [
          {
            key: 'shadow_switch',
            label: 'Enable shadow',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'shadow_offset_x',
            label: 'X offset',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/shadow_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'shadow_offset_y',
            label: 'Y offset',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/shadow_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'shadow_blur',
            label: 'Blur',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/shadow_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'shadow_spread',
            label: 'Spread',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/shadow_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'shadow_color',
            label: 'Color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
            conditional_fields: [
              {
                key: 'styles/shadow_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'background_blur_switch',
            label: 'Enable background blur',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'background_blur',
            label: 'Background blur',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/background_blur_switch',
                condition_value: true,
              },
            ],
          },
        ],
      },
      // #endregion
    ],
  },
} as const;

export default manifest;
