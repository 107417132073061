import { twMerge } from 'tailwind-merge';
import type { ZappNavigationNavItem } from '~/services/layout/types';
import { getPluginConfig } from '~/utils/get-plugin-configuration';
import manifest from '../config/manifest';
import { useFetcher, useLoaderData } from '@remix-run/react';
import { Button, ManifestButton } from '../../../../components2/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from '~/components2/dropdown-menu';
import { loader } from '~/routes/_index';
import { useIsLoggedIn } from '~/hooks/use-is-logged-in';

// TODO: consider moving all tailwind config to here
// TODO: on fixed size where the text be aligned

const SignInButton = ({ label }: { label: string }) => (
  <ManifestButton className="sign-in-button" to="/login" label={label} />
);
const SignUpButton = ({ label }: { label: string }) => (
  <Button className="sign-up-button">{label}</Button>
);

const SignOutButton = ({ label }: { label: string }) => {
  const fetcher = useFetcher();

  const signOutHandler = () => {
    fetcher.submit({}, { action: '/api/logout', method: 'post' });
  };
  return (
    <Button onClick={signOutHandler} className="sign-out-button">
      {label}
    </Button>
  );
};

const DropDownItem = ({
  label,
  onClick,
  to,
}: {
  label: string;
  onClick?: (e: any) => void;
  to?: string;
}) => {
  return (
    <ManifestButton
      className="w-full"
      label={label}
      to={to}
      onClick={onClick}
    />
  );
};

const AccountDropdown = ({
  children,
  navItemId,
}: {
  children?: React.ReactNode;
  navItemId: string;
}) => {
  return (
    <DropdownMenu modal={false}>
      <DropdownMenuTrigger>
        <ManifestButton
          className="dropdown-trigger-button h-full"
          enabledImage
          enabledLabel={false}
          imgSrc="/account.svg"
        />
      </DropdownMenuTrigger>
      <DropdownMenuPortal>
        <DropdownMenuContent
          sideOffset={5}
          className={`${manifest.identifier}--${navItemId}-dropdown-content`}
        >
          {children}
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  );
};

const LogoutDropdownItem = ({
  show,
  label,
}: {
  show: boolean;
  label: string;
}) => {
  const fetcher = useFetcher();
  if (!show) return null;

  const signOutHandler = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    console.log('signing out');
    fetcher.submit({}, { action: '/api/logout', method: 'post' });
    console.log('signed out');
  };
  return <DropDownItem label={label} onClick={signOutHandler} />;
};

type DropdownVisibility =
  | 'when_signed_in'
  | 'when_signed_out'
  | 'always'
  | 'never';

export const AccountNavItem = ({
  className,
  dropDownItems,
  isSignedIn,
  labels,
  navItem,
  dropdownVisibility,
  showSignIn,
  showSignUp,
  showSignOut,
}: {
  className: string;
  dropDownItems?: { label: string; href: string }[];
  isSignedIn?: boolean;
  labels: {
    signIn: string;
    signUp: string;
    signOut: string;
  };
  navItem: any;
  dropdownVisibility: DropdownVisibility;
  showSignIn: boolean;
  showSignUp: boolean;
  showSignOut: boolean;
}) => {
  if (isSignedIn === undefined) return null;

  const showDropdown =
    (isSignedIn && dropdownVisibility === 'when_signed_in') ||
    (!isSignedIn && dropdownVisibility === 'when_signed_out') ||
    dropdownVisibility === 'always';

  return (
    <div className={twMerge(className, `flex gap-[var(--gap)]`)}>
      {showDropdown && (
        <AccountDropdown navItemId={navItem.id}>
          <div>
            {isSignedIn &&
              dropDownItems?.map((item) => (
                <DropDownItem
                  key={item.label}
                  label={item.label}
                  to={item.href}
                />
              ))}
          </div>
          <div>
            <LogoutDropdownItem show={isSignedIn} label={labels.signOut} />
            {!isSignedIn && <DropDownItem to="/login" label={labels.signIn} />}
          </div>
        </AccountDropdown>
      )}

      {showSignIn && !isSignedIn && <SignInButton label={labels.signIn} />}
      {showSignUp && !isSignedIn && <SignUpButton label={labels.signUp} />}
      {showSignOut && isSignedIn && <SignOutButton label={labels.signOut} />}
    </div>
  );
};

const AccountNavItemWrapper = ({
  navItem,
}: {
  navItem: ZappNavigationNavItem;
}) => {
  const { staticLinks } = useLoaderData<typeof loader>();

  const { isLoggedIn, preventRender } = useIsLoggedIn();

  if (preventRender) return null;

  const { localizations, styles } = getPluginConfig<typeof manifest>(
    manifest,
    navItem
  );

  const labels = {
    signIn: localizations.sign_in_button_label,
    signUp: localizations.sign_up_button_label,
    signOut: localizations.sign_out_button_label,
    myAccount: localizations.dropdown_button_label,
  };

  function createDropDownLink(extraLinkNumber: 1 | 2 | 3 | 4) {
    if (styles[`extra_link_${extraLinkNumber}_type`] === 'external_link') {
      return styles[`extra_link_${extraLinkNumber}_external_link`];
    } else {
      const { link } = staticLinks.find(
        (staticLink) =>
          staticLink.screenId === styles[`extra_link_${extraLinkNumber}_target`]
      ) || { link: '/' };

      return link;
    }
  }

  const dropdownItems = [
    {
      label: styles.extra_link_1_label,
      href: createDropDownLink(1),
    },
    {
      label: styles.extra_link_2_label,
      href: createDropDownLink(2),
    },
    {
      label: styles.extra_link_3_label,
      href: createDropDownLink(3),
    },
    {
      label: styles.extra_link_4_label,
      href: createDropDownLink(4),
    },
  ];

  return (
    <AccountNavItem
      dropdownVisibility={
        styles.accounts_dropdown_visibility as DropdownVisibility
      }
      navItem={navItem}
      className={`${manifest.identifier}--${navItem.id}`}
      labels={labels}
      dropDownItems={dropdownItems.filter((item) => item.href && item.label)}
      isSignedIn={!!isLoggedIn}
      showSignIn={styles.sign_in_switch}
      showSignUp={false}
      showSignOut={false}
    />
  );
};

export default AccountNavItemWrapper;
