import type { ZappNavigationNavItem } from '~/services/layout/types';
import * as Dialog from '@radix-ui/react-dialog';
import React, { useEffect } from 'react';
import { tailwindStyles } from './styles';
import { WrapperContent } from './WrapperContent';
import { useLocation } from '@remix-run/react';
import ToggleNavBarButton from './button';
import { useScreenWidth } from '~/utils/screen-width';
import { useDrawerStore } from './use-drawer-store';

type PropsWithNavItem = {
  navItem: ZappNavigationNavItem;
};

const DrawerRoot = ({
  children,
  ...props
}: Omit<Dialog.DialogProps, 'open' | 'onOpenChange'> & {
  dialogOpenState: boolean;
  toggleDrawer: any;
}) => {
  const { dialogOpenState, toggleDrawer } = props;

  const location = useLocation();
  const screenWidth = useScreenWidth();

  useEffect(() => {
    toggleDrawer(null);
  }, [location]);

  useEffect(() => {
    if (!dialogOpenState) return;

    toggleDrawer(null);
  }, [screenWidth]);

  return (
    <Dialog.Root {...props} open={dialogOpenState} modal={false}>
      {children}
    </Dialog.Root>
  );
};

export default function NavDrawer({
  className,
  navItem,
}: PropsWithNavItem & { className?: string }) {
  const { openDrawer, toggleDrawer } = useDrawerStore();
  const isOpen = openDrawer === navItem.id;

  return (
    <DrawerRoot dialogOpenState={isOpen} toggleDrawer={toggleDrawer}>
      <ToggleNavBarButton
        navItem={navItem}
        dialogOpenState={isOpen}
        toggleDrawer={toggleDrawer}
        isOpen={isOpen}
      />
      <Dialog.Portal>
        <Dialog.Content asChild>
          <div className={[className, tailwindStyles.portal].join(' ')}>
            <WrapperContent navItem={navItem} />
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </DrawerRoot>
  );
}
