import { useEffect, useState } from 'react';

export function useScreenWidth(): number {
  const [screenSize, setScreenSize] = useState(0);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setScreenSize(window.innerWidth);
    }
    const listener = () => {
      setScreenSize(window.innerWidth);
    };
    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, []);

  return screenSize;
}
