import { Slot } from '@radix-ui/react-slot';
import { Link } from '@remix-run/react';
import React from 'react';
import { cn } from '~/components2/utils';
import { getColor, getPixels } from '~/utils/css-units';

export const createGroupButtonStyles = ({
  styles,
  prefix,
}: {
  styles: any; // TODO: restore types
  prefix: string;
}) => {
  return {
    '--pt': getPixels(styles[`${prefix}_padding_top`]),
    '--pb': getPixels(styles[`${prefix}_padding_bottom`]),
    '--pl': getPixels(styles[`${prefix}_padding_left`]),
    '--pr': getPixels(styles[`${prefix}_padding_right`]),
    '--mt': getPixels(styles[`${prefix}_margin_top`]),

    //  TODO: '--w': '100px',
    // TODO: '--decoration-thickness': getPixels(styles.sign_in_decoration_thickness),
    // TODO:  '--decoration--color': 'blue',
    '--rounded': getPixels(styles[`${prefix}_corner_radius`]),
    '--border-thickness': getPixels(styles[`${prefix}_border_thickness`]),

    '--font-family': styles[`${prefix}_font_family`],
    '--text-size': getPixels(styles[`${prefix}_font_size`]),
    '--text-transform': styles[`${prefix}_text_transform`],
  };
};

export const createButtonStyles = ({
  styles,
  prefix,
}: {
  styles: any; // TODO: restore types
  prefix: string;
}) => {
  return {
    '--text-color': getColor(styles[`${prefix}_color`]),
    '--text-hover-color': getColor(styles[`${prefix}_hover_color`]),
    '--border-color': getColor(styles[`${prefix}_border_color`]),
    '--border-hover-color': getColor(styles[`${prefix}_border_hover_color`]),
    '--bg': getColor(styles[`${prefix}_background_color`]),
    '--bg-hover': getColor(styles[`${prefix}_background_hover_color`]),
    '--w-asset': getPixels(styles[`${prefix}_asset_width`]),
    '--h-asset': getPixels(styles[`${prefix}_asset_height`]),
    '--ml-asset': getPixels(styles[`${prefix}_asset_margin_left`]),
    '--mr-asset': getPixels(styles[`${prefix}_asset_margin_right`]),
  };
};

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  asChild?: boolean;
  imgSrc?: string;
  enabledImage?: boolean;
  enabledLabel?: boolean;
  removeDefaultPadding?: boolean;
}

// Taken from https://ui.shadcn.com/docs/components/button
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      removeDefaultPadding = false,
      style,
      asChild = false,
      imgSrc,
      enabledImage = false,
      enabledLabel = true,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        style={{
          textDecorationThickness: 'var(--decoration-thickness)',
          borderWidth: 'var(--border-thickness)',
          borderColor: 'var(--border-color)',

          textTransform: 'var(--text-transform)' as 'uppercase' | 'capitalize',
        }}
        className={cn(
          className,

          'pt-[var(--pt)]',
          // 'pr-[var(--pr)]',
          'pb-[var(--pb)]',
          // 'pl-[var(--pl)]',
          // { 'pt-[var(--pt)]': !removeDefaultPadding },
          { 'pr-[var(--pr)]': !enabledImage && enabledLabel },
          // { 'pb-[var(--pb)]': !removeDefaultPadding },
          { 'pl-[var(--pl)]': !enabledImage && enabledLabel },
          'bg-[var(--bg)]',
          'hover:bg-[var(--bg-hover)]',
          'mt-[var(--mt)]',
          'mb-[var(--mb)]',
          'ml-[var(--ml)]',
          'mr-[var(--mr)]',
          'rounded-[var(--rounded)]',
          // 'w-[var(--w)]',

          'decoration-[color:var(--decoration--color)]',
          'hover:decoration-[color:var(--decoration-hover)]',
          'hover:border-[color:var(--border-hover-color)]',
          'text-[var(--text-color)]',
          'hover:text-[var(--text-hover-color)]'
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = 'Button';

export const ManifestButton = ({
  className,
  style,
  to,
  label,
  enabledLabel = true,
  enabledImage = false,
  onClick,
  imgSrc,
}: {
  className?: string;
  style?: any;
  to?: string;
  label?: string;
  enabledLabel?: boolean;
  enabledImage?: boolean;
  onClick?: any;
  imgSrc?: string;
}) => {
  const body = (
    <div className={cn('flex h-full w-full content-center items-center')}>
      {enabledImage && (
        <img
          className={cn(
            'block object-contain',
            'h-[var(--h-asset)]',
            'w-[var(--w-asset)]',
            'pt-[var(--pt-asset)]',
            'pr-[var(--pr-asset)]',
            'pb-[var(--pb-asset)]',
            'pl-[var(--pl-asset)]',
            'mt-[var(--mt-asset)]',
            'mb-[var(--mb-asset)]',
            'ml-[var(--ml-asset)]',
            'mr-[var(--mr-asset)]'
          )}
          alt=""
          src={imgSrc}
        />
      )}
      {enabledLabel && (
        <span
          className={'block cursor-pointer text-[length:var(--text-size)] '}
          style={{
            fontFamily: 'var(--font-family)',
          }}
        >
          {label}
        </span>
      )}
    </div>
  );
  if (to) {
    return (
      <Link to={to} prefetch="viewport">
        <Button
          enabledLabel={enabledLabel}
          style={style}
          className={cn(className)}
          // asChild
        >
          {body}
        </Button>
      </Link>
    );
  }
  return (
    <Button
      enabledLabel={enabledLabel}
      style={style}
      onClick={onClick}
      className={className}
      asChild
    >
      {body}
    </Button>
  );
};
