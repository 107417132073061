const manifest = {
  plugin_id: 3616,
  api: {},
  dependency_repository_url: [],
  dependency_name: '',
  author_name: 'Applicaster',
  author_email: 'zapp@applicaster.com',
  name: 'Menu List',
  description: 'Menu List',
  type: 'nav_item',
  characteristics: { group: true },
  supported_nav_items: ['responsive_nav_bar_menu_list_item'],
  max_nav_items: 10,
  screen: false,
  react_native: true,
  identifier: 'responsive_nav_bar_menu_list',
  ui_builder_support: true,
  whitelisted_account_ids: [],
  deprecated_since_zapp_sdk: '',
  unsupported_since_zapp_sdk: '',
  targets: ['web'],
  ui_frameworks: ['quickbrick'],
  platform: 'web',
  dependency_version: '0.0.1-alpha-2',
  manifest_version: '0.0.1-alpha-3',
  min_zapp_sdk: '0.0.1',
  styles: {
    fields: [
      {
        key: 'display',
        label: 'Display',
        type: 'select',
        options: [
          { text: 'Horizontal', value: 'horizontal' },
          { text: 'Vertical', value: 'vertical' },
        ],
        initial_value: 'horizontal',
      },
      {
        key: 'horizontal_gutter',
        label: 'Horizontal gutter',
        type: 'number_input',
        initial_value: 16,
        conditional_fields: [
          {
            key: 'styles/display',
            condition_value: 'horizontal',
          },
        ],
      },
      {
        key: 'vertical_gutter',
        label: 'Vertical gutter',
        type: 'number_input',
        initial_value: 16,
        conditional_fields: [
          {
            key: 'styles/display',
            condition_value: 'vertical',
          },
        ],
      },
      {
        key: 'columns',
        label: 'Number of columns',
        type: 'number_input',
        initial_value: 1,
        conditional_fields: [
          {
            key: 'styles/display',
            condition_value: 'vertical',
          },
        ],
      },
      {
        key: 'columns_horizontal_gutter',
        label: 'Columns horizontal gutter',
        type: 'number_input',
        initial_value: 8,
        conditional_fields: [
          {
            key: 'styles/display',
            condition_value: 'vertical',
          },
        ],
      },
      {
        key: 'alignment',
        label: 'Alignment',
        type: 'select',
        options: [
          { text: 'Left', value: 'left' },
          { text: 'Center', value: 'center' },
          { text: 'Right', value: 'right' },
        ],
        initial_value: 'left',
      },
      {
        key: 'padding_top',
        label: 'Padding top',
        type: 'number_input',
        initial_value: 0,
      },
      {
        key: 'padding_right',
        label: 'Padding right',
        type: 'number_input',
        initial_value: 0,
      },
      {
        key: 'padding_bottom',
        label: 'Padding bottom',
        type: 'number_input',
        initial_value: 0,
      },
      {
        key: 'padding_left',
        label: 'Padding left',
        type: 'number_input',
        initial_value: 0,
      },
      {
        key: 'margin_top',
        label: 'Margin top',
        type: 'number_input',
        initial_value: 0,
      },
      {
        key: 'margin_right',
        label: 'Margin right',
        type: 'number_input',
        initial_value: 0,
      },
      {
        key: 'margin_bottom',
        label: 'Margin bottom',
        type: 'number_input',
        initial_value: 0,
      },
      {
        key: 'margin_left',
        label: 'Margin left',
        type: 'number_input',
        initial_value: 0,
      },
      {
        key: 'background_color',
        label: 'Background color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(0, 0, 0, 0)',
      },
      {
        key: 'border_color',
        label: 'Border color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(0, 0, 0, 0)',
      },
      {
        key: 'border_thickness',
        label: 'Border thickness',
        type: 'number_input',
        initial_value: 0,
      },
      {
        key: 'corner_radius',
        label: 'Corner radius',
        type: 'number_input',
        initial_value: 0,
      },
      {
        label: 'Effects',
        group: true,
        fields: [
          {
            key: 'shadow_switch',
            label: 'Enable shadow',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'shadow_x_offset',
            label: 'X offset',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/shadow_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'shadow_y_offset',
            label: 'Y offset',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/shadow_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'shadow_blur',
            label: 'Blur',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/shadow_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'shadow_spread',
            label: 'Spread',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/shadow_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'shadow_color',
            label: 'Color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
          },
          {
            key: 'background_blur_switch',
            label: 'Enable background blur',
            type: 'switch',
            initial_value: false,
          },
        ],
      },
      {
        label: 'Menu Items',
        group: true,
        fields: [
          {
            key: 'item_size',
            label: 'Item size',
            type: 'select',
            options: [
              { text: 'Dynamic', value: 'dynamic' },
              { text: 'Fixed', value: 'fixed' },
              { text: 'Fill', value: 'fill' },
            ],
            initial_value: 'dynamic',
          },
          {
            key: 'item_width',
            label: 'Item width',
            type: 'number_input',
            initial_value: 120,
            conditional_fields: [
              {
                key: 'styles/item_size',
                condition_value: 'fixed',
              },
            ],
          },
          {
            key: 'item_alignment',
            label: 'Alignment',
            type: 'select',
            options: [
              { text: 'Left', value: 'left' },
              { text: 'Center', value: 'center' },
              { text: 'Right', value: 'right' },
            ],
            initial_value: 'left',
            conditional_fields: [
              {
                key: 'styles/item_size',
                condition_value: 'fixed',
              },
              {
                key: 'styles/item_size',
                condition_value: 'fill',
              },
            ],
          },
        ],
      },
      {
        label: 'Asset',
        group: true,
        fields: [
          {
            key: 'asset_switch',
            label: 'Enable',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'asset_width',
            label: 'Width',
            type: 'number_input',
            initial_value: 24,
            conditional_fields: [
              {
                key: 'styles/asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'asset_height',
            label: 'Height',
            type: 'number_input',
            initial_value: 24,
            conditional_fields: [
              {
                key: 'styles/asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'asset_padding_top',
            label: 'Padding top',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'asset_padding_right',
            label: 'Padding right',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'asset_padding_bottom',
            label: 'Padding bottom',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'asset_padding_left',
            label: 'Padding left',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'asset_margin_top',
            label: 'Margin top',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'asset_margin_right',
            label: 'Margin right',
            type: 'number_input',
            initial_value: 8,
            conditional_fields: [
              {
                key: 'styles/asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'asset_margin_bottom',
            label: 'Margin bottom',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'asset_margin_left',
            label: 'Margin left',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'hover_asset_switch',
            label: 'Enable hover asset',
            type: 'switch',
            initial_value: false,
            conditional_fields: [
              {
                key: 'styles/asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'selected_asset_switch',
            label: 'Enable selected asset',
            type: 'switch',
            initial_value: false,
            conditional_fields: [
              {
                key: 'styles/asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'selected_hover_asset_switch',
            label: 'Enable selected & hover asset',
            type: 'switch',
            initial_value: false,
            conditional_fields: [
              {
                key: 'styles/asset_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'asset_alignment',
            label: 'Alignment',
            type: 'select',
            options: [
              { text: 'Left', value: 'left' },
              { text: 'Right', value: 'right' },
            ],
            initial_value: 'Left',
            conditional_fields: [
              {
                key: 'styles/asset_switch',
                condition_value: true,
              },
            ],
          },
        ],
      },
      {
        label: 'Title',
        group: true,
        fields: [
          {
            key: 'title_switch',
            label: 'Enable',
            type: 'switch',
            initial_value: true,
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'title_default_font_color',
            label: 'Default font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(158, 158, 158, 1)',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'title_hover_font_color',
            label: 'Hover font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'title_selected_font_color',
            label: 'Selected font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'title_selected_hover_font_color',
            label: 'Selected & hover font color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(239, 239, 239, 1)',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'title_font_family',
            label: 'Font family',
            type: 'web_font_selector',
            initial_value: 'Ubuntu-Medium',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'title_font_size',
            label: 'Font size',
            type: 'number_input',
            initial_value: 14,
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'title_line_height',
            label: 'Line height',
            type: 'number_input',
            initial_value: 24,
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'title_letter_spacing',
            label: 'Letter spacing',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'title_text_transform',
            label: 'Text transform',
            type: 'select',
            options: [
              { text: 'None', value: 'none' },
              { text: 'Capitalize', value: 'capitalize' },
              { text: 'Uppercase', value: 'uppercase' },
              { text: 'Lowercase', value: 'lowercase' },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'title_default_text_decoration',
            label: 'Default text decoration',
            type: 'select',
            options: [
              { text: 'None', value: 'none' },
              { text: 'Underline', value: 'underline' },
              { text: 'Strikethrough', value: 'line-through' },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'title_hover_text_decoration',
            label: 'Hover text decoration',
            type: 'select',
            options: [
              { text: 'None', value: 'none' },
              { text: 'Underline', value: 'underline' },
              { text: 'Strikethrough', value: 'line-through' },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'title_selected_text_decoration',
            label: 'Selected text decoration',
            type: 'select',
            options: [
              { text: 'None', value: 'none' },
              { text: 'Underline', value: 'underline' },
              { text: 'Strikethrough', value: 'line-through' },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'title_selected_hover_text_decoration',
            label: 'Selected & hover text decoration',
            type: 'select',
            options: [
              { text: 'None', value: 'none' },
              { text: 'Underline', value: 'underline' },
              { text: 'Strikethrough', value: 'line-through' },
            ],
            initial_value: 'none',
            conditional_fields: [
              {
                key: 'styles/title_switch',
                condition_value: true,
              },
            ],
          },
        ],
      },
      {
        label: 'Item',
        group: true,
        fields: [
          {
            key: 'item_default_underline_color',
            label: 'Default underline color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
          },
          {
            key: 'item_hover_underline_color',
            label: 'Hover underline color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
          },
          {
            key: 'item_selected_underline_color',
            label: 'Selected underline color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
          },
          {
            key: 'item_selected_hover_underline_color',
            label: 'Selected & hover underline color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
          },
          {
            key: 'item_underline_thickness',
            label: 'Underline thickness',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'item_underline_corner_radius',
            label: 'Underline corner radius',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'item_margin_top',
            label: 'Item margin top',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'item_margin_right',
            label: 'Item margin right',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'item_margin_bottom',
            label: 'Item margin bottom',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'item_margin_left',
            label: 'Item margin left',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'item_padding_top',
            label: 'Item padding top',
            type: 'number_input',
            initial_value: 8,
          },
          {
            key: 'item_padding_right',
            label: 'Item padding right',
            type: 'number_input',
            initial_value: 16,
          },
          {
            key: 'item_padding_bottom',
            label: 'Item padding bottom',
            type: 'number_input',
            initial_value: 8,
          },
          {
            key: 'item_padding_left',
            label: 'Item padding left',
            type: 'number_input',
            initial_value: 16,
          },
          {
            key: 'item_default_background_color',
            label: 'Default item background color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
          },
          {
            key: 'item_hover_background_color',
            label: 'Hover item background color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(30, 30, 30, 1)',
          },
          {
            key: 'item_selected_background_color',
            label: 'Selected item background color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
          },
          {
            key: 'item_selected_hover_background_color',
            label: 'Selected & hover item background color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(30, 30, 30, 1)',
          },
          {
            key: 'item_default_item_border_color',
            label: 'Default item border color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
          },
          {
            key: 'item_hover_border_color',
            label: 'Hover item border color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
          },
          {
            key: 'item_selected_border_color',
            label: 'Selected item border color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
          },
          {
            key: 'item_selected_hover_border_color',
            label: 'Selected & hover item border color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
          },
          {
            key: 'item_border_thickness',
            label: 'Item border thickness',
            type: 'number_input',
            initial_value: 0,
          },
          {
            key: 'item_corner_radius',
            label: 'Item corner radius',
            type: 'number_input',
            initial_value: 8,
          },
        ],
      },
    ],
  },
} as const;

export default manifest;
