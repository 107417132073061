/*
Top Menu
======

+ Horizontal gutter
+ item size
- Item width
+ Default underline color
+ Hover underline color
+ Underline thickness
- Underline corner radius //TODO: this cannot be implemented now
+ All margin
+ All padding
+ Item border thickness
+ Corner radius
+ Default item background color
- Hover item background color

Button Specific
=====
+ Default item border color
+ Hover item border color

*/

import type {
  ExtractManifestKeys,
  ZappNavigationNavItem,
} from '~/services/layout/types';

import { createButton, createButtonWrapper } from '~/utils/manifest-utils';

const manifest = {
  plugin_id: 3612,
  identifier: 'responsive_nav_bar_account_button',
  manifest_version: '0.0.1-alpha-27',
  dependency_name: '',
  name: 'Account Button',
  description: 'Account Button',
  type: 'nav_item',
  styles: {
    fields: [
      {
        label: 'Top Menu exposed Buttons',
        group: true,
        tooltip:
          'You can expose the sign in, sign up, and sign out buttons in the top menu. or expose them in the account dropdown button.',
        fields: [
          {
            key: 'top_menu_horizontal_gutter',
            label: 'Top Menu Horizontal Gutter',
            type: 'number_input',
            initial_value: 8,
          },

          ...createButtonWrapper({
            keyPrefix: 'top_buttons',
            labelPrefix: 'Top Buttons',
            initialValues: {
              underlineThickness: 0,
              cornerRadius: 6,
              borderThickness: 1.5,
              fontFamily: 'Ubuntu-Medium',
              fontSize: 14,
              textTransform: 'uppercase',
              paddingTop: 8,
              paddingRight: 16,
              paddingLeft: 16,
              paddingBottom: 8,
              marginTop: 0,
              marginBottom: 0,
              marginLeft: 0,
              marginRight: 0,
              underlineColor: 'transparent',
            },
          }),

          // Sign In button
          ...createButton({
            keyPrefix: 'sign_in',
            labelPrefix: 'Sign In',
            initialValues: {
              backgroundColor: 'transparent',
              backgroundHoverColor: 'transparent',
              borderColor: 'rgba(239, 239, 239, 0.25)',
              borderHoverColor: 'rgba(239, 239, 239, 1)',
              color: 'rgba(239, 239, 239, 1)',
              hoverColor: 'rgba(239, 239, 239, 1)',
              enabled: true,
              underlineThickness: 0,
            },
          }),
          // Dropdown Trigger
          ...createButton({
            keyPrefix: 'dropdown_trigger',
            labelPrefix: 'Dropdown Trigger',
            initialValues: {
              backgroundColor: 'transparent',
              backgroundHoverColor: 'transparent',
              borderColor: 'rgba(239, 239, 239, 0.25)',
              borderHoverColor: 'rgba(239, 239, 239, 1)',
              color: 'rgba(239, 239, 239, 1)',
              hoverColor: 'rgba(239, 239, 239, 1)',

              underlineThickness: 0,
            },
          }),

          // Dropdown Buttons
          ...createButtonWrapper({
            keyPrefix: 'dropdown_buttons',
            labelPrefix: 'Dropdown Buttons',
            initialValues: {
              underlineThickness: 0,
              cornerRadius: 0,
              borderThickness: 0,
              fontFamily: 'sans-serif',
              fontSize: 14,
              textTransform: 'none',
              paddingTop: 3,
              paddingRight: 0,
              paddingLeft: 3,
              paddingBottom: 3,
              marginTop: 0,
              marginBottom: 0,
              marginLeft: 0,
              marginRight: 0,
              underlineColor: 'transparent',
            },
          }),
          ...createButton({
            keyPrefix: 'dropdown_buttons',
            labelPrefix: 'Dropdown Button',
            initialValues: {
              backgroundColor: 'transparent',
              backgroundHoverColor: 'rgba(0,0,0,0.25)',
              borderColor: 'transparent',
              borderHoverColor: 'transparent',
              color: 'rgba(239, 239, 239, 0.8)',
              hoverColor: 'rgba(239, 239, 239, 1)',

              underlineThickness: 0,
            },
          }),
        ],
      },
      {
        label: 'Account Dropdown',
        group: true,
        fields: [
          {
            key: 'accounts_dropdown_visibility',
            label: 'Accounts Dropdown Visibility',
            type: 'select',
            options: [
              { text: 'Only When Signed In', value: 'when_signed_in' },
              { text: 'Only When Signed Out', value: 'when_signed_out' },
              { text: 'Always', value: 'always' },
              { text: 'Never', value: 'never' },
            ],
            initial_value: 'when_signed_in',
          },
          {
            key: 'accounts_dropdown_bg_color',
            label: 'Background Color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(30 ,30 ,30 ,1)',
          },
          {
            key: 'extra_link_1_type',
            label: 'Link 1 Type',
            type: 'select',
            options: [
              {
                value: 'screen_target',
                text: 'Screen',
              },
              {
                value: 'external_link',
                text: 'External Link',
              },
            ],
            initial_value: 'screen_target',
          },
          {
            key: 'extra_link_1_target',
            label: 'Link 1 Target',
            type: 'screen_selector',
            conditional_fields: [
              {
                key: 'styles/extra_link_1_type',
                condition_value: 'screen_target',
              },
            ],
          },
          {
            key: 'extra_link_1_external_link',
            label: 'External Link 1 Path',
            type: 'text_input',
            conditional_fields: [
              {
                key: 'styles/extra_link_1_type',
                condition_value: 'external_link',
              },
            ],
          },
          {
            key: 'extra_link_1_label',
            label: 'Link 1 Label',
            type: 'text_input',
          },
          // Link 2
          {
            key: 'extra_link_2_type',
            label: 'Link 2 Type',
            type: 'select',
            options: [
              {
                value: 'screen_target',
                text: 'Screen',
              },
              {
                value: 'external_link',
                text: 'External Link',
              },
            ],
            initial_value: 'screen_target',
          },
          {
            key: 'extra_link_2_target',
            label: 'Link 2 Target',
            type: 'screen_selector',
            conditional_fields: [
              {
                key: 'styles/extra_link_2_type',
                condition_value: 'screen_target',
              },
            ],
          },
          {
            key: 'extra_link_2_external_link',
            label: 'External Link 2 Path',
            type: 'text_input',
            conditional_fields: [
              {
                key: 'styles/extra_link_2_type',
                condition_value: 'external_link',
              },
            ],
          },
          {
            key: 'extra_link_2_label',
            label: 'Link 2 Label',
            type: 'text_input',
          },
          // Link 3

          {
            key: 'extra_link_3_type',
            label: 'Link 3 Type',
            type: 'select',
            options: [
              {
                value: 'screen_target',
                text: 'Screen',
              },
              {
                value: 'external_link',
                text: 'External Link',
              },
            ],
            initial_value: 'screen_target',
          },
          {
            key: 'extra_link_3_target',
            label: 'Link 3 Target',
            type: 'screen_selector',
            conditional_fields: [
              {
                key: 'styles/extra_link_3_type',
                condition_value: 'screen_target',
              },
            ],
          },
          {
            key: 'extra_link_3_external_link',
            label: 'External Link 3 Path',
            type: 'text_input',
            conditional_fields: [
              {
                key: 'styles/extra_link_3_type',
                condition_value: 'external_link',
              },
            ],
          },
          {
            key: 'extra_link_3_label',
            label: 'Link 3 Label',
            type: 'text_input',
          },
          // Link 4
          {
            key: 'extra_link_4_type',
            label: 'Link 4 Type',
            type: 'select',
            options: [
              {
                value: 'screen_target',
                text: 'Screen',
              },
              {
                value: 'external_link',
                text: 'External Link',
              },
            ],
            initial_value: 'screen_target',
          },
          {
            key: 'extra_link_4_target',
            label: 'Link 4 Target',
            type: 'screen_selector',
            conditional_fields: [
              {
                key: 'styles/extra_link_4_type',
                condition_value: 'screen_target',
              },
            ],
          },
          {
            key: 'extra_link_4_external_link',
            label: 'External Link 4 Path',
            type: 'text_input',
            conditional_fields: [
              {
                key: 'styles/extra_link_4_type',
                condition_value: 'external_link',
              },
            ],
          },
          {
            key: 'extra_link_4_label',
            label: 'Link 4 Label',
            type: 'text_input',
          },
        ],
      },
    ],
  },
  localizations: {
    fields: [
      {
        key: 'sign_in_button_label',
        label: 'Sign in button label',
        type: 'text_input',
        initial_value: 'Sign In',
      },
      {
        key: 'sign_out_button_label',
        label: 'Sign out button label',
        type: 'text_input',
        initial_value: 'Sign Out',
      },
      {
        key: 'sign_up_button_label',
        label: 'Sign up button label',
        type: 'text_input',
        initial_value: 'Sign Up',
      },
      {
        key: 'dropdown_button_label',
        label: 'Dropdown button label',
        type: 'text_input',
        initial_value: 'My Account',
      },
    ],
  },
} as const;

export default manifest;

type Styles = ExtractManifestKeys<typeof manifest.styles.fields>;
// type General = ExtractManifestKeys<typeof manifest.general.fields>;

export type NavBarAccountButton = ZappNavigationNavItem<
  typeof manifest.identifier,
  {},
  Styles
>;
