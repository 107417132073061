import { create } from 'zustand';

interface DrawerStore {
  openDrawer: string | null;
  toggleDrawer: (drawerId: string) => void;
}

export const useDrawerStore = create<DrawerStore>((set) => ({
  openDrawer: null,
  toggleDrawer: (drawerId) =>
    set((state) => ({
      openDrawer: state.openDrawer === drawerId ? null : drawerId,
    })),
}));
