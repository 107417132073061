import { useFetcher, useLocation, useNavigation } from '@remix-run/react';
import { useEffect, useState } from 'react';
import {
  getVersionedIsLoggedInKey,
  IS_LOGGED_IN_KEY,
  isLoggedInClientState,
} from '~/utils/is-logged-in-client-state';

/**
 * This hook sets the `isLoggedIn` value in client storage.
 * It calls the `/api/is-logged-in` endpoint to get the `isLoggedIn` value
 * only if the value is not already set in client storage.
 */
export function useLoadIsLoggedIn() {
  const fetcher = useFetcher<{ isLoggedIn: boolean }>({
    key: IS_LOGGED_IN_KEY,
  });

  const location = useLocation();

  const { isLoggedIn } = useIsLoggedIn();

  useEffect(() => {
    const callLoginApi: boolean =
      !isLoggedIn && fetcher.state === 'idle' && !fetcher.data;

    if (!callLoginApi) return;

    fetcher.submit({}, { action: '/api/is-logged-in', method: 'post' });
  }, [fetcher.state, location.pathname, isLoggedIn]);
}

export function useIsLoggedIn(): {
  isLoggedIn: boolean | null;
  preventRender: boolean;
} {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const [preventRender, setPreventRender] = useState<boolean>(true);

  const location = useLocation();

  const fetcher = useFetcher<{ isLoggedIn: boolean }>({
    key: IS_LOGGED_IN_KEY,
  });

  const navigation = useNavigation();

  const getAndSetIsLoggedIn = async () => {
    const isLoggedInValue: boolean | null = await isLoggedInClientState().get();
    setIsLoggedIn(isLoggedInValue);
  };

  useEffect(() => {
    getAndSetIsLoggedIn();
  }, [fetcher.state]);

  useEffect(() => {
    setPreventRender(
      (isLoggedIn === null && navigation.state !== 'loading') ||
        location.pathname.toUpperCase() === '/LOGIN'
    );
  }, [navigation.state, isLoggedIn === null]);

  useSyncIsLoggedInAcrossTabs(setIsLoggedIn);

  return { isLoggedIn, preventRender };
}

function useSyncIsLoggedInAcrossTabs(
  setIsLoggedIn: (isLoggedIn: boolean | null) => void
) {
  const syncIsLoggedInAcrossTabs = (event: StorageEvent) => {
    if (event.key !== getVersionedIsLoggedInKey()) return;
    const newValue: string | null = event.newValue;
    if (newValue === null) return;
    setIsLoggedIn(newValue === 'true');
  };

  useEffect(() => {
    window.addEventListener('storage', syncIsLoggedInAcrossTabs);

    return () => {
      window.removeEventListener('storage', syncIsLoggedInAcrossTabs);
    };
  }, []);
}

export async function useMockIsLoggedIn(isMock: boolean): Promise<void> {
  useEffect(() => {
    if (!isMock) return;

    window.versionInfo = { APP_VERSION_UUID: 'mock' };

    (async () => {
      await isLoggedInClientState().set(true);
    })();
  }, []);
}
