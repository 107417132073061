const manifest = {
  plugin_id: 3578,
  api: {},
  dependency_repository_url: [],
  dependency_name: '',
  author_name: 'Applicaster',
  author_email: 'zapp@applicaster.com',
  name: 'Nav Bar Breakpoint',
  description: 'Nav Bar Breakpoint',
  type: 'nav_item',
  characteristics: { group: true },
  supported_nav_items: ['responsive_nav_bar_container'],
  screen: false,
  react_native: true,
  identifier: 'responsive_nav_bar_breakpoint',
  ui_builder_support: true,
  whitelisted_account_ids: [],
  deprecated_since_zapp_sdk: '',
  unsupported_since_zapp_sdk: '',
  targets: ['web'],
  ui_frameworks: ['quickbrick'],
  platform: 'web',
  dependency_version: '0.0.1-alpha-4',
  manifest_version: '0.0.1-alpha-7',
  min_zapp_sdk: '0.0.1',
  max_nav_items: 3,
  styles: {
    fields: [
      {
        key: 'background_type',
        label: 'Background type',
        type: 'select',
        options: [
          { text: 'Color', value: 'color' },
          { text: 'Gradient', value: 'gradient' },
        ],
        initial_value: 'color',
      },
      {
        key: 'background_color',
        label: 'Background color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(17, 17, 17, 1)',
        conditional_fields: [
          {
            key: 'styles/background_type',
            condition_value: 'color',
          },
        ],
      },
      {
        key: 'top_gradient_color',
        label: 'Top gradient color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(17, 17, 17, 1)',
        conditional_fields: [
          {
            key: 'styles/background_type',
            condition_value: 'gradient',
          },
        ],
      },
      {
        key: 'bottom_gradient_color',
        label: 'Bottom gradient color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(17, 17, 17, 0)',
        conditional_fields: [
          {
            key: 'styles/background_type',
            condition_value: 'gradient',
          },
        ],
      },
      {
        key: 'border_color',
        label: 'Border color',
        type: 'color_picker_rgba',
        initial_value: 'rgba(0, 0, 0, 0)',
      },
      {
        key: 'border_thickness',
        label: 'Border thickness',
        type: 'number_input',
        initial_value: 0,
      },
      {
        key: 'padding_left',
        label: 'Padding left',
        type: 'number_input',
        initial_value: 12,
      },
      {
        key: 'padding_right',
        label: 'Padding right',
        type: 'number_input',
        initial_value: 12,
      },
      {
        key: 'padding_top',
        label: 'Padding top',
        type: 'number_input',
        initial_value: 16,
      },
      {
        key: 'padding_bottom',
        label: 'Padding bottom',
        type: 'number_input',
        initial_value: 16,
      },
      {
        key: 'margin_top',
        label: 'Margin top',
        type: 'number_input',
        initial_value: 0,
      },
      {
        key: 'margin_right',
        label: 'Margin right',
        type: 'number_input',
        initial_value: 0,
      },
      {
        key: 'margin_bottom',
        label: 'Margin bottom',
        type: 'number_input',
        initial_value: 0,
      },
      {
        key: 'margin_left',
        label: 'Margin left',
        type: 'number_input',
        initial_value: 0,
      },
      {
        key: 'corner_radius',
        label: 'Corner radius',
        type: 'number_input',
        initial_value: 0,
      },
      {
        label: 'Effects',
        group: true,
        fields: [
          {
            key: 'shadow_switch',
            label: 'Enable shadow',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'x_offset',
            label: 'X offset',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/shadow_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'y_offset',
            label: 'Y offset',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/shadow_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'blur',
            label: 'Blur',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/shadow_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'spread',
            label: 'Spread',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/shadow_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'color',
            label: 'Color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
            conditional_fields: [
              {
                key: 'styles/shadow_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'background_blur_switch',
            label: 'Enable background blur',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'background_blur',
            label: 'Background blur',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/background_blur_switch',
                condition_value: true,
              },
            ],
          },
        ],
      },
      {
        label: 'Scroll transition',
        group: true,
        fields: [
          {
            key: 'scroll_transition_switch',
            label: 'Enable',
            type: 'switch',
            initial_value: false,
          },
          {
            key: 'scroll_trigger',
            label: 'Scroll trigger (Y value)',
            type: 'number_input',
            conditional_fields: [
              {
                key: 'styles/scroll_transition',
                condition_value: true,
              },
            ],
          },
          {
            key: 'change_background_color_switch',
            label: 'Change background color',
            type: 'switch',
            initial_value: false,
            conditional_fields: [
              {
                key: 'styles/scroll_transition',
                condition_value: true,
              },
              {
                key: 'styles/background_type',
                condition_value: 'color',
              },
            ],
          },
          {
            key: 'new_background_color',
            label: 'New background color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
            conditional_fields: [
              {
                key: 'styles/scroll_transition',
                condition_value: true,
              },
              {
                key: 'styles/background_type',
                condition_value: 'color',
              },
            ],
          },
          {
            key: 'new_top_gradient_color',
            label: 'New top gradient color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
            conditional_fields: [
              {
                key: 'styles/scroll_transition',
                condition_value: true,
              },
              {
                key: 'styles/background_type',
                condition_value: 'gradient',
              },
            ],
          },
          {
            key: 'new_bottom_gradient_color',
            label: 'New bottom gradient color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
            conditional_fields: [
              {
                key: 'styles/scroll_transition',
                condition_value: true,
              },
              {
                key: 'styles/background_type',
                condition_value: 'gradient',
              },
            ],
          },
          {
            key: 'change_border_color_switch',
            label: 'Change border color',
            type: 'switch',
            initial_value: false,
            conditional_fields: [
              {
                key: 'styles/scroll_transition',
                condition_value: true,
              },
            ],
          },
          {
            key: 'new_border_color',
            label: 'New border color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
            conditional_fields: [
              {
                key: 'styles/scroll_transition',
                condition_value: true,
              },
              {
                key: 'styles/change_border_color_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'change_background_blur_switch',
            label: 'Change background blur',
            type: 'switch',
            initial_value: false,
            conditional_fields: [
              {
                key: 'styles/scroll_transition',
                condition_value: true,
              },
              {
                key: 'styles/background_blur_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'new_background_blur',
            label: 'New background blur',
            type: 'number_input',
            initial_value: 0,
            conditional_fields: [
              {
                key: 'styles/scroll_transition',
                condition_value: true,
              },
              {
                key: 'styles/background_blur_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'change_shadow_color_switch',
            label: 'Change shadow color',
            type: 'switch',
            initial_value: false,
            conditional_fields: [
              {
                key: 'styles/scroll_transition',
                condition_value: true,
              },
              {
                key: 'styles/shadow_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'new_shadow_color',
            label: 'New shadow color',
            type: 'color_picker_rgba',
            initial_value: 'rgba(0, 0, 0, 0)',
            conditional_fields: [
              {
                key: 'styles/scroll_transition',
                condition_value: true,
              },
              {
                key: 'styles/shadow_switch',
                condition_value: true,
              },
              {
                key: 'styles/change_shadow_color_switch',
                condition_value: true,
              },
            ],
          },
          {
            key: 'transition_duration',
            label: 'Transition duration',
            type: 'number_input',
            initial_value: 0.3,
            conditional_fields: [
              {
                key: 'styles/scroll_transition',
                condition_value: true,
              },
            ],
          },
          {
            key: 'transition_easing',
            label: 'Transition easing',
            type: 'select',
            options: [
              { text: 'linear', value: 'linear' },
              { text: 'ease', value: 'ease' },
              { text: 'ease-in', value: 'ease-in' },
              { text: 'ease-out', value: 'ease-out' },
              { text: 'ease-in-out', value: 'ease-in-out' },
            ],
            initial_value: 'ease-in-out',
            conditional_fields: [
              {
                key: 'styles/scroll_transition',
                condition_value: true,
              },
            ],
          },
        ],
      },
    ],
  },
  general: {
    fields: [
      {
        key: 'breakpoint',
        label: 'Breakpoint',
        type: 'select',
        options: [
          {
            text: 'Large Desktop',
            value: '1344',
          },
          {
            text: 'Desktop',
            value: '1024',
          },
          {
            text: 'Tablet',
            value: '640',
          },
          {
            text: 'Mobile',
            value: '0',
          },
        ],
        initial_value: '0',
      },
    ],
  },
} as const;

export default manifest;
