const manifest = {
  plugin_id: 3617,
  api: {},
  dependency_repository_url: [],
  dependency_name: '',
  author_name: 'Applicaster',
  author_email: 'zapp@applicaster.com',
  name: 'Menu List Item',
  description: 'Menu List Item',
  type: 'nav_item',
  supported_nav_items: ['responsive_nav_bar_menu_list_item'],
  screen: false,
  react_native: true,
  identifier: 'responsive_nav_bar_menu_list_item',
  ui_builder_support: true,
  whitelisted_account_ids: [],
  deprecated_since_zapp_sdk: '',
  unsupported_since_zapp_sdk: '',
  targets: ['web'],
  ui_frameworks: ['quickbrick'],
  platform: 'web',
  manifest_version: '0.0.1-alpha-8',
  assets: {
    fields: [
      {
        key: 'asset_default',
        label: 'Asset default',
        type: 'uploader',
      },
      {
        key: 'asset_hover',
        label: 'Asset hover',
        type: 'uploader',
      },
      {
        key: 'asset_selected',
        label: 'Asset selected',
        type: 'uploader',
      },
      {
        key: 'asset_selected_hover',
        label: 'Asset selected & hover',
        type: 'uploader',
      },
    ],
  },
  general: {
    fields: [
      {
        key: 'link_type',
        label: 'Link Type',
        type: 'select',
        options: [
          {
            value: 'screen_target',
            text: 'Screen',
          },
          {
            value: 'external_link',
            text: 'External Link',
          },
        ],
        initial_value: 'screen_target',
      },
      {
        key: 'target',
        label: 'Target',
        type: 'screen_selector',
        conditional_fields: [
          {
            key: 'general/link_type',
            condition_value: 'screen_target',
          },
        ],
      },
      {
        key: 'external_link',
        label: 'External Link Path',
        type: 'text_input',
        conditional_fields: [
          {
            key: 'general/link_type',
            condition_value: 'external_link',
          },
        ],
      },
      {
        key: 'external_link_label',
        label: 'External Link Label',
        type: 'text_input',
        conditional_fields: [
          {
            key: 'general/link_type',
            condition_value: 'external_link',
          },
        ],
      },
    ],
  },
} as const;

export default manifest;
