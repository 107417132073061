import { NavLink, useLoaderData } from '@remix-run/react';

import type { ZappNavigationNavItem } from '~/services/layout/types';
import type { loader } from '~/routes/_index';
import { getPluginConfig } from '~/utils/get-plugin-configuration';
import manifest from '../config/manifest';
import { twMerge } from 'tailwind-merge';
import { getJustifyContentClassName } from '~/utils/styles';

const flexDirectionMap = {
  left: 'flex-row',
  right: 'flex-row-reverse',
};

function getFlexDirection(alignment?: string) {
  return alignment
    ? flexDirectionMap[alignment as keyof typeof flexDirectionMap]
    : 'left';
}

function NavBarButton({ navItem }: { navItem: ZappNavigationNavItem }) {
  const { staticLinks } = useLoaderData<typeof loader>();

  const staticLink = staticLinks.find(
    (link) => link.screenId === navItem?.data?.target
  );

  const { styles, assets } = getPluginConfig<typeof manifest>(
    manifest,
    navItem
  );

  const {
    title_switch,
    title_text_transform,
    button_title_default_text_decoration,
    button_title_hover_text_decoration,
    button_content_alignment,
    mode,
    size,
  } = styles;

  const {
    asset_switch,
    button_default_asset,
    button_hover_asset_switch,
    button_hover_asset,
    button_selected_asset_switch,
    button_selected_asset,
    button_selected_hover_asset_switch,
    button_selected_hover_asset,
    asset_alignment,
  } = assets;

  const isInactive: boolean = mode === 'inactive';

  return (
    <div
      className={`nav-bar-item-${navItem.id} group flex flex-col ${
        isInactive ? 'pointer-events-none' : ''
      }`}
    >
      <NavLink role="menuitem" to={staticLink ? staticLink.link : '/'}>
        {({ isActive }) => (
          <>
            <div
              className={
                `border-nav-bar-button-width border-nav-bar-button-color hover:border-nav-bar-button-hover-color ` +
                twMerge(
                  `relative mb-nav-bar-button-b ml-nav-bar-button-l mr-nav-bar-button-r mt-nav-bar-button-t flex w-nav-bar-button-width items-center rounded-nav-bar-button ${getFlexDirection(
                    asset_alignment
                  )} bg-nav-bar-button pb-nav-bar-button-b pl-nav-bar-button-l pr-nav-bar-button-r pt-nav-bar-button-t text-nav-bar-button-color 
                hover:bg-nav-bar-button-hover hover:text-nav-bar-button-color-hover`,
                  !isInactive &&
                    isActive &&
                    'border-nav-bar-button-selected-item-border-color bg-nav-bar-button-selected-item-background-color text-nav-bar-button-color-active group-hover:border-nav-bar-button-selected-hover-item-border-color group-hover:bg-nav-bar-button-selected-hover-item-background-color',
                  size === 'fixed' &&
                    getJustifyContentClassName(button_content_alignment)
                )
              }
            >
              {asset_switch && button_default_asset && (
                <div className="pb-nav-bar-button-asset-b pl-nav-bar-button-asset-l pr-nav-bar-button-asset-r pt-nav-bar-button-asset-t mb-nav-bar-button-asset-b ml-nav-bar-button-asset-l mr-nav-bar-button-asset-r mt-nav-bar-button-asset-t">
                  <div
                    className={twMerge(
                      `h-nav-bar-button-asset w-nav-bar-button-asset`,
                      button_default_asset &&
                        `bg-nav-bar-button-image bg-contain bg-center bg-no-repeat`,
                      button_hover_asset_switch &&
                        button_hover_asset &&
                        'group-hover:bg-nav-bar-button-image-hover',
                      !isInactive &&
                        isActive &&
                        button_selected_asset_switch &&
                        button_selected_asset &&
                        'bg-nav-bar-button-image-selected',
                      !isInactive &&
                        isActive &&
                        button_selected_hover_asset_switch &&
                        button_selected_hover_asset &&
                        'group-hover:bg-nav-bar-button-image-selected-hover'
                    )}
                  />
                </div>
              )}
              {title_switch && navItem.title.trim().length !== 0 && (
                <span
                  className={twMerge(
                    `font-nav-bar-button text-nav-bar-button-font-size leading-nav-bar-button tracking-nav-bar-button ${button_title_default_text_decoration} hover:${button_title_hover_text_decoration} ${title_text_transform} group-hover:nav-bar-button-title-hover-text-decoration`,
                    !isInactive &&
                      isActive &&
                      'nav-bar-button-title-selected-text-decoration group-hover:text-nav-bar-button-title-selected-hover-font-color group-hover:nav-bar-button-title-selected-hover-text-decoration'
                  )}
                >
                  {navItem.title}
                </span>
              )}
            </div>
            <div className="relative">
              <div
                className={twMerge(
                  `absolute bottom-0 left-0 right-0 top-0 h-nav_bar_button_underline_thickness_height rounded-nav_bar_button_underline_radius bg-nav_bar_button_button_default_underline_color group-hover:bg-nav_bar_button_button_hover_underline_color`,
                  !isInactive &&
                    isActive &&
                    'bg-nav_bar_button_button_selected_underline_color group-hover:bg-nav_bar_button_button_selected_hover_underline_color'
                )}
              ></div>
            </div>
          </>
        )}
      </NavLink>
    </div>
  );
}

export default NavBarButton;
