import { tailwindStyles } from './styles';
import { NavItem } from '../../nav-bar/components';
import { getPluginConfig } from '~/utils/get-plugin-configuration';
import manifest from '../config/manifest';
import { useMemo } from 'react';
import { sortByPositionKey } from '~/services/layout/layout';

export const WrapperContent = ({ navItem }: any) => {
  const { styles } = getPluginConfig<typeof manifest>(manifest, navItem);

  const {
    overlay_color,
    background_blur,
    shadow_offset_x,
    shadow_offset_y,
    shadow_blur,
    shadow_spread,
    shadow_color,
    background_blur_switch,
    shadow_switch,
    mode,
    align_horizontal,
    align_vertical,
    drawer_max_width_switch,
    drawer_max_width,
    drawer_alignment,
  } = styles;

  let wrapperStyle: React.CSSProperties = {
    backgroundColor: overlay_color as string,
  };

  if (background_blur_switch) {
    wrapperStyle.backdropFilter = `blur(${background_blur}px)`;
    wrapperStyle.WebkitBackdropFilter = `blur(${background_blur}px)`;
  }

  let innerStyle = {};

  if (shadow_switch) {
    innerStyle = Object.assign(innerStyle, {
      boxShadow: `${shadow_offset_x}px ${shadow_offset_y}px ${shadow_blur}px ${shadow_spread}px ${shadow_color}`,
    });
  }

  const isMaxWidth = isDrawerMaxWidth({
    drawer_max_width_switch,
    drawer_max_width,
    mode,
  });

  const wrapperStyles = useMemo(
    () =>
      [
        `nav-bar-item-${navItem.id}`,
        'pointer-events-auto',
        tailwindStyles.wrapper,
      ].join(' '),
    [navItem.id]
  );

  return (
    <div className={wrapperStyles} style={wrapperStyle}>
      <div
        className={`flex h-screen ${
          isMaxWidth ? 'nav-bar-drawer-max-width' : 'w-screen'
        } ${
          isMaxWidth
            ? getDrawerAlignment(
                drawer_alignment as 'left' | 'center' | 'right'
              )
            : ''
        }`}
      >
        <div
          style={innerStyle}
          className={`mb-nav-bar-drawer-b ml-nav-bar-drawer-l mr-nav-bar-drawer-r mt-nav-bar-drawer-t flex w-full flex-1 flex-col items-center`}
        >
          <div
            style={{
              justifyContent: align_vertical,
              alignItems: align_horizontal,
            }}
            className={`${mode === 'fullscreen' ? 'flex-1' : ''}
          flex w-full flex-col 
          gap-nav-bar-drawer rounded-nav-bar-drawer border-nav-bar-drawer-width border-nav-bar-drawer-color bg-nav-bar-drawer pb-nav-bar-drawer-b pl-nav-bar-drawer-l pr-nav-bar-drawer-r pt-nav-bar-drawer-t`}
          >
            {sortByPositionKey(navItem.nav_items)?.map((item: any) => {
              return <NavItem key={item.id} navItem={item} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

function isDrawerMaxWidth({
  drawer_max_width_switch,
  drawer_max_width,
  mode,
}: {
  drawer_max_width_switch: boolean;
  drawer_max_width: number;
  mode: string;
}): boolean {
  return (
    drawer_max_width_switch && !isNaN(drawer_max_width) && mode === 'modal'
  );
}

function getDrawerAlignment(
  drawer_alignment: 'left' | 'center' | 'right'
): string {
  switch (drawer_alignment) {
    case 'left':
      return 'fixed top-0 left-0';
    case 'center':
      return 'fixed top-0 left-1/2 transform -translate-x-1/2';
    case 'right':
      return 'fixed top-0 right-0';
    default:
      return 'fixed top-0 left-0';
  }
}
