import NavBarMenuListItem from '~/plugins/nav-bar-menu-list-item/components';
import type {
  NavBarMenuListItemType,
  ZappNavigationNavItem,
} from '~/services/layout/types';
import { getPluginConfig } from '~/utils/get-plugin-configuration';
import manifest from '../config/manifest';
import { getAlignItemsClassName } from '~/utils/styles';
import logger from '~/services/logger';
import { sortByPositionKey } from '~/services/layout/layout';

export default function NavBarMenuList({
  navItem,
}: {
  navItem: ZappNavigationNavItem;
}) {
  const { styles } = getPluginConfig<typeof manifest>(manifest, navItem);
  const {
    display,
    columns,
    item_size,
    asset_switch,
    title_switch,
    asset_alignment,
    hover_asset_switch,
    selected_asset_switch,
    selected_hover_asset_switch,
    alignment,
    item_alignment,
  } = styles;

  const numOfItems = navItem?.nav_items?.length;

  if (!numOfItems) return <></>;

  const verticalDisplayColumnsClasses: string[] =
    getVerticalDisplayColumnsClasses({
      columns,
      numOfItems,
      display,
    });

  return (
    <div
      tabIndex={-1}
      className={`nav-bar-item-${navItem.id} ${getDisplayClasses({
        display,
        columns,
      })} mb-menu-list-b ml-menu-list-l mr-menu-list-r mt-menu-list-t rounded-menu-list border-menu-list-width border-menu-list-color bg-menu-list pb-menu-list-b pl-menu-list-l pr-menu-list-r pt-menu-list-t ${getAlignItemsClassName(
        { alignment, display }
      )} ${display === 'vertical' ? 'w-full' : ''}`}
    >
      {sortByPositionKey(navItem.nav_items)?.map((navItem, i) => {
        return (
          <div
            className={verticalDisplayColumnsClasses[i] || ''}
            key={navItem.id}
          >
            <NavBarMenuListItem
              navItem={navItem as NavBarMenuListItemType}
              itemSize={item_size}
              assetSwitch={asset_switch}
              hoverAssetSwitch={hover_asset_switch}
              selectedAssetSwitch={selected_asset_switch}
              selectedHoverAssetSwitch={selected_hover_asset_switch}
              titleSwitch={title_switch}
              assetAlignment={asset_alignment}
              alignment={item_alignment}
            />
          </div>
        );
      })}
    </div>
  );
}

function getVerticalDisplayColumnsClasses({
  columns,
  numOfItems,
  display,
}: {
  columns: number;
  numOfItems: number | undefined;
  display: string;
}) {
  try {
    if (display !== 'vertical' || !numOfItems) return [];

    columns = columns || 1;

    const numberOfItemsInRow = Math.ceil(numOfItems / columns);

    let rowStartNum = 0;
    const verticalDisplayColumnsClasses: string[] = [];

    [...Array(columns)].map((_, columnIndex) => {
      const colStartNum = columnIndex + 1;

      [...Array(numberOfItemsInRow)].map((_, rowStartIndex) => {
        rowStartNum++;

        if (rowStartNum > numOfItems || rowStartIndex > numOfItems) return;

        verticalDisplayColumnsClasses.push(
          `col-start-${colStartNum} row-start-${rowStartIndex + 1}`
        );
      });
    });

    return verticalDisplayColumnsClasses;
  } catch (error: any) {
    logger.info(`getVerticalDisplayColumnsClasses: ${error.message}`);
    return [];
  }
}

function getDisplayClasses({
  display,
  columns,
}: {
  display: string;
  columns: number;
}) {
  return display === 'horizontal'
    ? 'flex flex-row gap-menu-list-horizontal'
    : `grid grid-cols-${
        columns || 1
      } gap-y-menu-list-vertical gap-x-menu-list-columns-horizontal-gutter`;
}
