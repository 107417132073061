import get from 'lodash.get';
import type { Fields, ManifestField } from '~/services/layout/types';
import type { FieldType } from './manifest-utils';

type Field = {
  readonly key: string;
  readonly tooltip?: string;
  readonly type: FieldType;
};

type Manifest<T> = T extends {
  indentifier?: string;
  general?: { fields: ReadonlyArray<infer G extends ManifestField> };
  styles?: { fields: ReadonlyArray<infer S extends ManifestField> };
  data?: { fields: ReadonlyArray<infer D extends ManifestField> };
  rules?: { fields: ReadonlyArray<infer R extends ManifestField> };
  assets?: { fields: ReadonlyArray<infer A extends ManifestField> };
  localizations?: { fields: ReadonlyArray<infer L extends ManifestField> };
  custom_configuration_fields?: ReadonlyArray<infer C extends ManifestField>;
}
  ? {
      identifier?: string;
      general?: { fields: ReadonlyArray<G> };
      styles?: { fields: ReadonlyArray<S> };
      rules?: { fields: ReadonlyArray<R> };
      data?: { fields: ReadonlyArray<D> };
      assets?: { fields: ReadonlyArray<A> };
      localizations?: { fields: ReadonlyArray<L> };
      custom_configuration_fields?: ReadonlyArray<C>;
    }
  : never;

type GroupField = {
  readonly group: true;
  readonly fields: readonly Field[];
};

type FieldOrGroupField = Field | GroupField;

type ManifestPlugin = {
  plugin_id: number;
  readonly general?: {
    readonly fields: readonly FieldOrGroupField[];
  };
  readonly styles?: {
    readonly fields: readonly FieldOrGroupField[];
  };
  readonly data?: {
    readonly fields: readonly FieldOrGroupField[];
  };
  readonly rules?: {
    readonly fields: readonly FieldOrGroupField[];
  };
  readonly assets?: {
    readonly fields: readonly FieldOrGroupField[];
  };
  readonly custom_configuration_fields?: readonly FieldOrGroupField[];
};

/**
 * Get the configuration for a plugin.
 * @param manifest - The manifest of the plugin.
 * @param configOverride - The plugin object.
 * @returns - The configuration object w/ the plugin initial values from the manifest as needed.
 */
export function getPluginConfig<M extends ManifestPlugin>(
  manifest: Manifest<M>,
  configOverride: any,
  omitManifestInitialValues = false
) {
  type General<T> = T extends { general?: infer G }
    ? Fields<G extends { fields: any } ? G['fields'] : never>
    : never;
  type Styles<T> = T extends { styles?: infer S }
    ? Fields<S extends { fields: any } ? S['fields'] : never>
    : never;
  type Rules<T> = T extends { rules?: infer R }
    ? Fields<R extends { fields: any } ? R['fields'] : never>
    : never;
  type Data<T> = T extends { data?: infer D }
    ? Fields<D extends { fields: any } ? D['fields'] : never>
    : never;
  type Assets<T> = T extends { assets?: infer A }
    ? Fields<A extends { fields: any } ? A['fields'] : never>
    : never;
  type Localizations<T> = T extends { localizations?: infer L }
    ? Fields<L extends { fields: any } ? L['fields'] : never>
    : never;
  type CustomConfiguration<T> = T extends {
    custom_configuration_fields?: infer C;
  }
    ? Fields<C>
    : never;

  const manifestGeneral: readonly ManifestField[] | undefined =
    manifest?.general?.fields;
  const manifestStyles: readonly ManifestField[] | undefined =
    manifest?.styles?.fields;
  const manifestRules: readonly ManifestField[] | undefined =
    manifest?.rules?.fields;
  const manifestData: readonly ManifestField[] | undefined =
    manifest?.data?.fields;
  const manifestAssets: readonly ManifestField[] | undefined =
    manifest?.assets?.fields;
  const manifestLocalizations: readonly ManifestField[] | undefined =
    manifest?.localizations?.fields;
  const manifestCustomConfiguration: readonly ManifestField[] | undefined =
    manifest?.custom_configuration_fields;

  const general: General<M> = getPluginValues<General<M>>({
    manifestFields: manifestGeneral,
    plugin: configOverride?.general,
  });

  const styles: Styles<M> = getPluginValues<Styles<M>>({
    manifestFields: manifestStyles,
    plugin: configOverride?.styles,
  });

  const rules: Rules<M> = getPluginValues<Rules<M>>({
    manifestFields: manifestRules,
    plugin: configOverride?.rules,
  });

  const data: Data<M> = getPluginValues<Data<M>>({
    manifestFields: manifestData,
    plugin: configOverride?.data,
  });

  const assets: Assets<M> = getPluginValues<Assets<M>>({
    manifestFields: manifestAssets,
    plugin: configOverride?.assets,
  });

  const localizations: Localizations<M> = getLocalizationsPluginValues<
    Localizations<M>
  >({
    manifestFields: manifestLocalizations,
    plugin:
      configOverride?.localizations ||
      getGeneralPluginConfiguration({
        configOverride,
        manifest,
        key: 'configuration_json',
      })?.localizations,
  });

  const customConfiguration: CustomConfiguration<M> = getPluginValues<
    CustomConfiguration<M>
  >({
    manifestFields: manifestCustomConfiguration,
    plugin: getGeneralPluginConfiguration({
      configOverride,
      manifest,
      key: 'configuration_json',
    }),
    omitManifestInitialValues,
  });

  return {
    general,
    styles,
    rules,
    data,
    assets,
    localizations,
    customConfiguration,
  };
}

/**
 * Get the plugin configuration of general plugins.
 * @param configOverride - The plugin configurations of all the plugins.
 * @param manifest - The manifest of the plugin.
 * @param key - The key to get the configuration from the plugin.
 * @returns The configuration of the plugin or undefined.
 */
function getGeneralPluginConfiguration({
  configOverride,
  manifest,
  key,
}: {
  configOverride: any;
  manifest: any;
  key: 'localizations' | 'configuration_json';
}) {
  try {
    return configOverride.find((configuration: any) => {
      if (configuration?.plugin?.identifier === manifest.identifier) {
        return configuration;
      }
    })[key];
  } catch (error) {
    return undefined;
  }
}

/**
 * Get the plugin values.
 * @param manifest - The manifest of the plugin.
 * @param plugin - The plugin object.
 * @returns The initial object with the plugin values.
 */
function getPluginValues<T>({
  manifestFields,
  plugin,
  omitManifestInitialValues = false,
}: {
  manifestFields: any;
  plugin: any;
  omitManifestInitialValues?: boolean;
}) {
  const initialObject: Record<string, any> = {};

  if (!manifestFields) return initialObject as T;

  manifestFields.forEach((f: any) => {
    const groupFields = f?.fields;

    if (groupFields) {
      f.fields.forEach((f: any) => {
        initialObject[f.key] = getPluginFieldValue({
          pluginFields: plugin,
          f,
          omitManifestInitialValues,
        });
      });
      return;
    }

    initialObject[f.key] = getPluginFieldValue({
      pluginFields: plugin,
      f,
      omitManifestInitialValues,
    });
  });

  return initialObject as T;
}

/**
 * Get the value of a plugin field.
 * @param pluginFields The fields of the plugin.
 * @param f The field object.
 * @returns The value of the plugin field || the initial value if the value
 * is not set.
 */
export function getPluginFieldValue({
  pluginFields,
  f,
  omitManifestInitialValues = false,
}: {
  pluginFields: any;
  f: Record<string, any>;
  omitManifestInitialValues?: boolean;
}) {
  const value = get(pluginFields, f.key);

  if (value === false) return false;

  if (value === 0) return 0;

  if (omitManifestInitialValues) return value;

  return value || get(f, 'initial_value');
}

/**
 * Get the first localized strings of the plugin localizations object.
 * @param pluginLocalizations - The localizations of the plugin.
 * @returns The localized strings or undefined.
 */
function getLocalizedStrings(
  pluginLocalizations: Record<string, Record<string, string>>
): Record<string, string> | undefined {
  // TODO: Support multiple languages, considering the user's language.
  try {
    return pluginLocalizations[Object.keys(pluginLocalizations)[0]];
  } catch (error) {
    return undefined;
  }
}

/**
 * Get the localizations plugin values.
 * @param manifestFields - The manifest of the plugin.
 * @param plugin - The plugin object.
 */
function getLocalizationsPluginValues<T>({
  manifestFields,
  plugin,
}: {
  manifestFields: any;
  plugin: any;
}) {
  const initialObject: Record<string, any> = {};

  if (!manifestFields || !plugin) return initialObject as T;

  manifestFields.forEach((f: any) => {
    const localizedStrings = getLocalizedStrings(plugin);

    initialObject[f.key] =
      get(localizedStrings, f.key) || get(f, 'initial_value');
  });

  return initialObject as T;
}
