import { NavItem } from '~/plugins/nav-bar/components';
import type { NavBarDrawer } from '~/services/layout/types';
import {
  type NavBarContainerItem,
  type ZappNavigationNavItem,
} from '~/services/layout/types';
import { twMerge } from 'tailwind-merge';
import { sortByPositionKey } from '~/services/layout/layout';

function NavBarContainer({
  navItem,
  pos,
}: {
  navItem: ZappNavigationNavItem;
  pos: 'left' | 'center' | 'right' | 'drawer';
}) {
  return (
    <div className={twMerge('flex', pos === 'right' && 'justify-end')}>
      <div
        className={
          'border-nav-bar-container-width border-nav-bar-container-color ' +
          twMerge(
            `nav-bar-item-${navItem.id} mb-nav-bar-container-margin-b ml-nav-bar-container-margin-l mr-nav-bar-container-margin-r mt-nav-bar-container-margin-t flex items-center gap-nav-bar-container rounded-nav-bar-container bg-nav-bar-container pb-nav-bar-container-padding-b pl-nav-bar-container-padding-l pr-nav-bar-container-padding-r pt-nav-bar-container-padding-t`,
            pos === 'center' &&
              'absolute left-1/2 top-1/2 z-[6] -translate-x-1/2 -translate-y-1/2 transform',
            'flex-none'
          )
        }
      >
        {sortByPositionKey(navItem.nav_items)?.map((item) => {
          return <NavItem key={item.id} navItem={item} />;
        })}
      </div>
    </div>
  );
}

export function getNavBarContainers(
  navItems?: (NavBarContainerItem | NavBarDrawer)[]
) {
  return navItems?.reduce<{
    left?: ZappNavigationNavItem;
    center?: ZappNavigationNavItem;
    right?: ZappNavigationNavItem;
    drawer?: ZappNavigationNavItem;
  }>((acc, navItem) => {
    if (
      navItem.type === 'responsive_nav_bar_container' &&
      navItem.general?.position &&
      navItem.nav_items
    ) {
      acc[navItem.general.position as 'left' | 'center' | 'right'] = navItem;
    }

    return acc;
  }, {});
}

export default NavBarContainer;
