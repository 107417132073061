import type { ZappNavigationNavItem } from '~/services/layout/types';
import * as Dialog from '@radix-ui/react-dialog';
import manifest from '../../config/manifest';
import { getPluginConfig } from '~/utils/get-plugin-configuration';
import { twMerge } from 'tailwind-merge';
import { getFlexDirection } from '~/utils/buttons';

function ToggleNavBarButton({
  navItem,
  dialogOpenState,
  toggleDrawer,
  isOpen,
}: {
  navItem: ZappNavigationNavItem;
  dialogOpenState: boolean;
  toggleDrawer: any;
  isOpen: any;
}) {
  const config = getPluginConfig<typeof manifest>(manifest, navItem);

  const toggleClosedLabelText = config.localizations?.toggle_closed_label_text;
  const toggleOpenLabelText = config.localizations?.toggle_open_label_text;
  const titleSwitch = config.styles?.title_switch;
  const assetSwitch = config.assets?.asset_switch;
  const buttonContentAlignment = config.styles?.button_content_alignment;

  return (
    <div
      className={`nav-bar-item-${
        navItem.id
      } group relative mb-nav-bar-button-b ml-nav-bar-button-l mr-nav-bar-button-r mt-nav-bar-button-t w-nav-bar-button-width cursor-pointer rounded-nav-bar-button border-nav-bar-button-width pb-nav-bar-button-b pl-nav-bar-button-l pr-nav-bar-button-r pt-nav-bar-button-t duration-500 ease-in-out
      ${
        dialogOpenState
          ? `border-nav-bar-button-toggle-opened-color bg-nav-bar-button-toggle-opened hover:border-nav-bar-button-toggle-opened-color-hover hover:bg-nav-bar-button-toggle-opened-hover`
          : `border-nav-bar-button-toggle-closed-color bg-nav-bar-button-toggle-closed hover:border-nav-bar-button-toggle-closed-color-hover hover:bg-nav-bar-button-toggle-closed-hover`
      }
      flex items-center ${buttonContentAlignment}`}
      onClick={(e) => {
        e.stopPropagation();
        toggleDrawer(isOpen ? null : navItem.id);
      }}
      data-testid={`nav-bar-drawer-button`}
    >
      <div
        className={twMerge(
          config?.styles?.underline_thickness === 0 && 'hidden',
          'absolute bottom-0 left-0 right-0 h-nav-bar-button-underline-thickness rounded-nav-bar-button-underline-corner-radius',
          dialogOpenState &&
            `bg-nav-bar-button-underline-opened group-hover:bg-nav-bar-button-underline-opened-hover`,
          !dialogOpenState &&
            `bg-nav-bar-button-underline-closed group-hover:bg-nav-bar-button-underline-closed-hover`
        )}
      ></div>

      <div
        className="pointer-events-none h-0 w-0 bg-nav-bar-button-closed-image-hover opacity-0"
        tabIndex={-1}
      ></div>
      <div
        className="pointer-events-none h-0 w-0 bg-nav-bar-button-opened-image-hover opacity-0"
        tabIndex={-1}
      ></div>

      <div
        className={`flex items-center ${getFlexDirection(
          config?.assets?.asset_alignment
        )}`}
      >
        <Dialog.Trigger asChild>
          <button className="peer mb-nav-bar-button-asset-b ml-nav-bar-button-asset-l mr-nav-bar-button-asset-r mt-nav-bar-button-asset-t data-[state=open]:hidden">
            <div
              className={twMerge(
                !assetSwitch && 'hidden',
                (config?.assets?.toggle_closed_default_asset ||
                  config?.assets?.toggle_closed_hover_asset) &&
                  'h-nav-bar-button-asset w-nav-bar-button-asset bg-nav-bar-button-closed-image',
                `bg-contain bg-center bg-no-repeat`,
                config?.assets?.toggle_enable_closed_hover_switch &&
                  `group-hover:bg-nav-bar-button-closed-image-hover`
              )}
            ></div>
          </button>
        </Dialog.Trigger>
        <Dialog.Close asChild>
          <button className="peer mb-nav-bar-button-asset-b ml-nav-bar-button-asset-l mr-nav-bar-button-asset-r mt-nav-bar-button-asset-t peer-data-[state=closed]:hidden">
            <div
              className={twMerge(
                !assetSwitch && 'hidden',
                (config?.assets?.toggle_open_default_asset ||
                  config?.assets?.toggle_open_hover_asset) &&
                  'h-nav-bar-button-asset w-nav-bar-button-asset bg-nav-bar-button-opened-image',
                `bg-contain bg-center bg-no-repeat`,
                config?.assets?.toggle_enable_open_hover_switch &&
                  `group-hover:bg-nav-bar-button-opened-image-hover`
              )}
            ></div>
          </button>
        </Dialog.Close>

        <p
          className={`${
            !(titleSwitch && (!!toggleClosedLabelText || !!toggleOpenLabelText))
              ? 'hidden'
              : ''
          }
            font-nav-bar-button text-nav-bar-button-font-size leading-nav-bar-button tracking-nav-bar-button nav-bar-button-title-text-transform
            ${
              dialogOpenState
                ? 'text-nav-bar-button-toggle-opened-color nav-bar-button-toggle-title-open-text-decoration group-hover:text-nav-bar-button-toggle-opened-color-hover group-hover:nav-bar-button-toggle-title-open-hover-text-decoration'
                : ''
            },
            ${
              !dialogOpenState
                ? 'text-nav-bar-button-toggle-closed-color nav-bar-button-toggle-title-closed-text-decoration group-hover:text-nav-bar-button-toggle-closed-color-hover group-hover:nav-bar-button-toggle-title-closed-hover-text-decoration'
                : ''
            }`}
        >
          {dialogOpenState
            ? toggleOpenLabelText || ''
            : toggleClosedLabelText || ''}
        </p>
      </div>
    </div>
  );
}

export default ToggleNavBarButton;
