export type FieldType =
  | 'text_input'
  | 'switch'
  | 'number_input'
  | 'uploader'
  | 'color_picker_rgba'
  | 'select'
  | 'web_font_selector'
  | 'screen_selector'
  | 'nav_bar_selector'
  | 'tag_select'
  | 'data_source_selector'
  | 'cell_style_selector';

export type Field<K extends string, T extends FieldType> = {
  readonly key: K;
  readonly label: string;
  readonly initial_value: any;
  readonly type: T;
  readonly options?: Array<{ text: string; value: string }>;
  readonly conditional_fields?: any;
};

export const createField = <K extends string, T extends FieldType>({
  key,
  type,
  label,
  initial_value,
  options,
  conditional_fields,
}: {
  readonly key: K;
  readonly type: T;
  readonly label: string;
  readonly initial_value?: any;
  readonly options?: Array<{ text: string; value: string }>;
  readonly conditional_fields?: any;
}): Field<K, T> => {
  return {
    key,
    label,
    initial_value,
    options,
    type,
    conditional_fields,
  } as const;
};

type ButtonKey =
  | 'paddingTop'
  | 'paddingBottom'
  | 'paddingRight'
  | 'paddingLeft'
  | 'marginTop'
  | 'marginBottom'
  | 'marginLeft'
  | 'marginRight'
  | 'enabled'
  | 'underlineColor'
  | 'buttonWidth'
  | 'underlineThickness'
  | 'borderThickness'
  | 'cornerRadius'
  | 'backgroundColor'
  | 'backgroundHoverColor'
  | 'borderColor'
  | 'borderHoverColor'
  | 'fontFamily'
  | 'fontSize'
  | 'textTransform'
  | 'color'
  | 'hoverColor'
  | 'deleteme';

export const createButtonWrapper = ({
  keyPrefix,
  labelPrefix,
  initialValues,
  applyConditionals = false,
}: {
  keyPrefix: string;
  labelPrefix: string;
  initialValues?: Partial<Record<ButtonKey, any>>;
  applyConditionals?: boolean;
}) => {
  const applyConditionalsArray = applyConditionals
    ? {
        conditional_fields: [
          {
            key: `styles/${keyPrefix}_switch`,
            condition_value: true,
          },
        ],
      }
    : {};
  return [
    createField({
      key: `${keyPrefix}_switch`,
      type: 'switch',
      label: `${labelPrefix} Enable`,
      initial_value: true,
    }),
    // Item size
    createField({
      key: `${keyPrefix}_button_size`,
      type: 'select',
      options: [
        { text: 'Dynamic', value: 'dynamic' },
        { text: 'Fixed', value: 'fixed' },
        { text: 'Fill', value: 'fill' },
      ],
      label: `${labelPrefix} Item Size`,
      initial_value: 'dynamic',
      ...applyConditionalsArray,
    }),
    // Item width
    createField({
      key: `${keyPrefix}_button_width`,
      type: 'number_input',
      label: `${labelPrefix} Button Width`,
      initial_value: initialValues?.buttonWidth,
      conditional_fields: [
        {
          key: `styles/${keyPrefix}_button_size`,
          condition_value: 'fixed',
        },
      ],
    }),
    // Default underline color
    createField({
      key: `${keyPrefix}_underline_color`,
      type: 'color_picker_rgba',
      label: `${labelPrefix} Underline Color`,
      initial_value: initialValues?.underlineColor || 'transparent',
      ...applyConditionalsArray,
    }),
    // TODO: Hover underline color

    // Underline thickness
    createField({
      key: `${keyPrefix}_underline_thickness`,
      type: 'number_input',
      label: `${labelPrefix} Underline Thickness`,
      initial_value: initialValues?.underlineThickness,
      ...applyConditionalsArray,
    }),
    // Item border thickness
    createField({
      key: `${keyPrefix}_border_thickness`,
      type: 'number_input',
      label: `${labelPrefix} Border Thickness`,
      initial_value: initialValues?.borderThickness,
      ...applyConditionalsArray,
    }),
    // Item corner radius
    createField({
      key: `${keyPrefix}_corner_radius`,
      type: 'number_input',
      label: `${labelPrefix} Corner Thickness`,
      initial_value: initialValues?.cornerRadius,
      ...applyConditionalsArray,
    }),
    // Text Section

    // Enable

    // Font family
    createField({
      key: `${keyPrefix}_font_family`,
      type: 'web_font_selector',
      label: `${labelPrefix} Font Family`,
      initial_value: initialValues?.fontFamily,
      ...applyConditionalsArray,
    }),

    // Font size

    createField({
      key: `${keyPrefix}_font_size`,
      type: 'number_input',
      label: `${labelPrefix} Font Size`,
      initial_value: initialValues?.fontSize,
      ...applyConditionalsArray,
    }),

    // Line height

    // Letter spacing

    // Text transform

    createField({
      key: `${keyPrefix}_text_transform`,
      type: 'number_input',
      label: `${labelPrefix} Text Transform`,
      initial_value: initialValues?.textTransform,
      ...applyConditionalsArray,
    }),
    // Padding
    createField({
      key: `${keyPrefix}_padding_top`,
      type: 'number_input',
      label: `${labelPrefix} Padding Top`,
      initial_value: initialValues?.paddingTop,
      conditional_fields: [
        {
          key: `styles/${keyPrefix}_switch`,
          condition_value: true,
        },
      ],
    }),
    createField({
      key: `${keyPrefix}_padding_right`,
      type: 'number_input',
      label: `${labelPrefix} Padding Right`,
      initial_value: initialValues?.paddingRight,
      conditional_fields: [
        {
          key: `styles/${keyPrefix}_switch`,
          condition_value: true,
        },
      ],
    }),
    createField({
      key: `${keyPrefix}_padding_bottom`,
      type: 'number_input',
      label: `${labelPrefix} Padding Bottom`,
      initial_value: initialValues?.paddingBottom,
      conditional_fields: [
        {
          key: `styles/${keyPrefix}_switch`,
          condition_value: true,
        },
      ],
    }),
    createField({
      key: `${keyPrefix}_padding_left`,
      type: 'number_input',
      label: `${labelPrefix} Padding Left`,
      initial_value: initialValues?.paddingLeft,
      conditional_fields: [
        {
          key: `styles/${keyPrefix}_switch`,
          condition_value: true,
        },
      ],
    }),
    //Margin
    createField({
      key: `${keyPrefix}_margin_top`,
      type: 'number_input',
      label: `${labelPrefix} Margin Top`,
      initial_value: initialValues?.marginTop,
      conditional_fields: [
        {
          key: `styles/${keyPrefix}_switch`,
          condition_value: true,
        },
      ],
    }),
    createField({
      key: `${keyPrefix}_margin_right`,
      type: 'number_input',
      label: `${labelPrefix} Margin Right`,
      initial_value: initialValues?.marginRight,
      conditional_fields: [
        {
          key: `styles/${keyPrefix}_switch`,
          condition_value: true,
        },
      ],
    }),
    createField({
      key: `${keyPrefix}_margin_bottom`,
      type: 'number_input',
      label: `${labelPrefix} Margin Bottom`,
      initial_value: initialValues?.marginBottom,
      conditional_fields: [
        {
          key: `styles/${keyPrefix}_switch`,
          condition_value: true,
        },
      ],
    }),
    createField({
      key: `${keyPrefix}_margin_left`,
      type: 'number_input',
      label: `${labelPrefix} Margin Left`,
      initial_value: initialValues?.marginLeft,
      conditional_fields: [
        {
          key: `styles/${keyPrefix}_switch`,
          condition_value: true,
        },
      ],
    }),

    // Default text decoration TODO: this is a duplicate

    // Hover text decoration
  ];
};

export const createButton = ({
  keyPrefix,
  labelPrefix,
  initialValues,
}: {
  keyPrefix: string;
  labelPrefix: string;
  initialValues: Partial<Record<ButtonKey, any>>;
}) => [
  // Enable
  createField({
    key: `${keyPrefix}_switch`,
    type: 'switch',
    label: `${labelPrefix} Enable`,
    initial_value: initialValues?.enabled,
  }),

  // Default item background color
  createField({
    key: `${keyPrefix}_background_color`,
    type: 'color_picker_rgba',
    label: `${labelPrefix} Background Color`,
    initial_value: initialValues?.backgroundColor,
    conditional_fields: [
      {
        key: `styles/${keyPrefix}_switch`,
        condition_value: true,
      },
    ],
  }),
  // Hover item background color
  createField({
    key: `${keyPrefix}_background_hover_color`,
    type: 'color_picker_rgba',
    label: `${labelPrefix} Background Hover Color`,
    initial_value: initialValues?.backgroundHoverColor,
    conditional_fields: [
      {
        key: `styles/${keyPrefix}_switch`,
        condition_value: true,
      },
    ],
  }),
  // Default item border color
  createField({
    key: `${keyPrefix}_border_color`,
    type: 'color_picker_rgba',
    label: `${labelPrefix} Border Color`,
    initial_value: initialValues?.borderColor,
    conditional_fields: [
      {
        key: `styles/${keyPrefix}_switch`,
        condition_value: true,
      },
    ],
  }),
  // Hover item border color
  createField({
    key: `${keyPrefix}_border_hover_color`,
    type: 'color_picker_rgba',
    label: `${labelPrefix} Border Hover Color`,
    initial_value: initialValues?.borderHoverColor,
    conditional_fields: [
      {
        key: `styles/${keyPrefix}_switch`,
        condition_value: true,
      },
    ],
  }),

  // Default font color
  createField({
    key: `${keyPrefix}_color`,
    type: 'color_picker_rgba',
    label: `${labelPrefix} Color`,
    initial_value: initialValues?.color,
    conditional_fields: [
      {
        key: `styles/${keyPrefix}_switch`,
        condition_value: true,
      },
    ],
  }),
  // Hover font color
  createField({
    key: `${keyPrefix}_hover_color`,
    type: 'color_picker_rgba',
    label: `${labelPrefix} Hover Color`,
    initial_value: initialValues?.hoverColor,
    conditional_fields: [
      {
        key: `styles/${keyPrefix}_switch`,
        condition_value: true,
      },
    ],
  }),

  // TODO move assets to assets section
  createField({
    key: `${keyPrefix}_asset_width`,
    type: 'number_input',
    label: `${labelPrefix} Asset Width`,
    initial_value: 24,
    conditional_fields: [
      {
        key: `styles/${keyPrefix}_switch`,
        condition_value: true,
      },
    ],
  }),

  createField({
    key: `${keyPrefix}_asset_height`,
    type: 'number_input',
    label: `${labelPrefix} Asset Height`,
    initial_value: 24,
    conditional_fields: [
      {
        key: `styles/${keyPrefix}_switch`,
        condition_value: true,
      },
    ],
  }),

  createField({
    key: `${keyPrefix}_asset_margin_right`,
    type: 'number_input',
    label: `${labelPrefix} Asset Margin Right`,
    initial_value: 0,
    conditional_fields: [
      {
        key: `styles/${keyPrefix}_switch`,
        condition_value: true,
      },
    ],
  }),

  createField({
    key: `${keyPrefix}_asset_margin_left`,
    type: 'number_input',
    label: `${labelPrefix} Asset Margin Left`,
    initial_value: 0,
    conditional_fields: [
      {
        key: `styles/${keyPrefix}_switch`,
        condition_value: true,
      },
    ],
  }),
];
