const flexDirectionMap = {
  left: 'flex-row',
  right: 'flex-row-reverse',
};

export const baseNavBarButtonStyles = [
  'cursor-pointer',
  'border-nav-bar-button-width',
  'rounded-nav-bar-button',
  'mb-nav-bar-button-b',
  'ml-nav-bar-button-l',
  'mr-nav-bar-button-r',
  'mt-nav-bar-button-t',
  'pb-nav-bar-button-b',
  'pl-nav-bar-button-l',
  'pr-nav-bar-button-r',
  'pt-nav-bar-button-t',
].join(' ');

export const buttonAssetStyles = [
  'mb-nav-bar-button-asset-b',
  'ml-nav-bar-button-asset-l',
  'mr-nav-bar-button-asset-r',
  'mt-nav-bar-button-asset-t',
  'pb-nav-bar-button-asset-b',
  'pl-nav-bar-button-asset-l',
  'pr-nav-bar-button-asset-r',
  'pt-nav-bar-button-asset-t',
].join(' ');

export const buttonAssetImageStyles = [
  'h-nav-bar-button-asset',
  'w-nav-bar-button-asset',
  'bg-contain',
  'bg-center',
  'bg-no-repeat',
].join(' ');

export const buttonTextStyles = [
  'font-nav-bar-button',
  'leading-nav-bar-button',
  'tracking-nav-bar-button',
].join(' ');

// type: button
export const buttonStyles = [
  'text-nav-bar-button-color',
  'hover:text-nav-bar-button-color-hover',
  'border-nav-bar-button-color',
  'hover:border-nav-bar-button-hover-color',
  'bg-nav-bar-button',
  'hover:bg-nav-bar-button-hover',
].join(' ');

// type: toggle
export const stateStyles = {
  opened: [
    'text-nav-bar-button-toggle-opened-color',
    'hover:text-nav-bar-button-toggle-opened-color-hover',
    'border-nav-bar-button-toggle-opened-color',
    'hover:border-nav-bar-button-toggle-opened-color-hover',
    'bg-nav-bar-button-toggle-opened',
    'hover:bg-nav-bar-button-toggle-opened-hover',
  ].join(' '),
  closed: [
    'text-nav-bar-button-toggle-closed-color',
    'hover:text-nav-bar-button-toggle-closed-color-hover',
    'border-nav-bar-button-toggle-closed-color',
    'hover:border-nav-bar-button-toggle-closed-color-hover',
    'bg-nav-bar-button-toggle-closed',
    'hover:bg-nav-bar-button-toggle-closed-hover',
  ].join(' '),
};

export function getFlexDirection(alignment?: string) {
  return alignment
    ? flexDirectionMap[alignment as keyof typeof flexDirectionMap]
    : 'left';
}

export function getToggleStateClassnames(
  state: 'opened' | 'closed',
  {
    toggle_open_default_asset,
    toggle_closed_default_asset,
    toggle_closed_hover_asset,
    toggle_open_hover_asset,
  }: {
    toggle_open_default_asset?: string;
    toggle_open_hover_asset?: string;
    toggle_closed_default_asset?: string;
    toggle_closed_hover_asset?: string;
  }
) {
  let hoverStateStyle = '';
  let baseStyle = '';
  if (state === 'opened' && toggle_open_default_asset) {
    baseStyle = 'bg-nav-bar-button-opened-image';
    hoverStateStyle = toggle_open_hover_asset
      ? `group-hover:bg-nav-bar-button-opened-image-hover`
      : '';
  } else if (state === 'closed' && toggle_closed_default_asset) {
    baseStyle = 'bg-nav-bar-button-closed-image';
    hoverStateStyle = toggle_closed_hover_asset
      ? `group-hover:bg-nav-bar-button-closed-image-hover`
      : '';
  }
  return `${baseStyle} ${hoverStateStyle}`.trim();
}
